<template>
  <div>
    <section class="section__alt pp-container" id="article">
      <h2 class="policy">Privacy Policy</h2>
      <article>
        <h3>Travella Terms of Use</h3>

        <p>
          These Terms and Conditions (<router-link
            class="link"
            :to="{ name: 'Policy' }"
            ><b>Terms</b> </router-link
          >) provide for how our service(s) may be used whether in person or on
          our platforms or any other affiliated service, platform, or apps –
          hereafter referred to as <b>“service”</b>. No extrinsic evidence,
          whether oral or written, will be incorporated into this Agreement
          before except to the extent permitted by law.

          <br /><br />
          By signing up or using any of our services, you signify your
          acceptance of our Terms which shall last for the term of Travella’s
          existence. If you do not accept the Terms stated here, please do not
          use our service and if you become aware of these terms after
          interacting with our platform, kindly write to us immediately to
          delete your records.

          <br /><br />
          The use of “Our” or “We” or “Travella” below shall refer to Travella
          Brooks Limited or any other company we incorporate to implement the
          Travella Solution. The use of “You” or “user” shall refer to any
          person, entity, or customer that uses or intends to use or procure any
          of the services or offerings on the Travella platform.

          <br /><br />
          These terms are dated the 30th day of October 2021 and may be updated
          from time to time at our sole discretion.<br /><br />
        </p>
      </article>

      <article>
        <h3>1. Contractual Relationship</h3>

        <p>
          You shall ensure that you are lawfully authorized to request our
          services and ensure that all information you provide is true and
          honest and we shall be immediately updated of any change in your
          information. You agree that if you are unsure of the meaning of any
          part of these terms, you will contact us for clarification prior to
          utilizing our service.<br /><br />
        </p>

        <h3>2. Privacy & Confidentiality</h3>

        <p>
          2.1 You assume all responsibility for compliance with all laws and
          regulations<br />2.2 We shall request various information from you to
          provide our services in the safest and most trustworthy way. By
          signing up or utilizing our services you unequivocally consent to the
          use of this information to render our services to you.<br />2.3 Where
          you are using our platform, we may also collect/utilize technical
          information to render our platform and deliver a unique experience to
          you. We shall not harvest or disclose your information for unrelated
          purposes.<br />2.4 We have the discretion to keep or delete your
          information from our platform. Hence there shall be no liability for
          deleting your information after a reasonable time or where we believe
          it is no longer needed.<br />2.5 Our platform may be integrated with
          third-party links and merchants like Flutterwave, Paystack, and you
          warrant that we shall not be responsible for their actions or
          inactions. In the event of a complaint, we reserve the right to refer
          you to the relevant merchant depending on their applicable terms and
          depending on the facts and circumstances.<br /><br />
        </p>

        <h3>3. User’s Capacity and Related Matters</h3>

        <p>
          By using our services you confirm that you are at least 18 years of
          age or (if below 18 years) that you are acting through the guidance of
          a parent or legal guardian. By using or requesting Travella’s
          services, you represent and warrant that:<br />

          <span style="margin-left: 1.5rem">
            3.5.1 All information provided by the User is true, complete, and
            accurate - neither fraudulent nor misleading in any respect.</span
          ><br />
          <span style="margin-left: 1.5rem"
            >3.5.2 You shall not utilize the platform for any illegal or
            prohibited purpose.</span
          >
          <br />
          <span style="margin-left: 1.5rem"
            >3.5.3 Travella reserves the right in its sole discretion, to refuse
            to provide its Services to any user where it deems so.</span
          ><br /><br />
        </p>

        <h3>4. Acceptance of Terms of Use</h3>

        <p>
          The use of Travella Services constitutes an acceptance of these Terms,
          including any subsequent amendments which may be made to the Travella
          <router-link class="link" :to="{ name: 'Policy' }"
            ><b>terms and conditions</b>
          </router-link>
          from time to time. Your use of our platform shall be regarded as your
          signature and consent.<br /><br />
        </p>

        <h3>5. Use of our Services</h3>

        <p>
          5.6. Unless otherwise indicated, the user’s address indicated on the
          platform is deemed to be the place of execution and the place of
          departure and the recipient's address provided is the place of
          destination.<br />5.7. We always make our best efforts to provide
          reasonable and affordable pricing for the users but we reserve all
          rights to vary or review the prices from time to time to reflect
          prevailing market conditions and rates.

          <br />5.8. We grant you a license to sign up and interact on our
          platform provided you do not defragment, sell or copy it (or a part
          thereof). <br />5.9. Travella reserves the right to cancel any order
          or transfer and refund any amount paid where we, in our sole
          discretion, determine that proceeding with such transaction will
          contravene our terms or be prejudicial to our interest. In the event
          where a refund is requested or is in issue, we reserve the right to
          credit your platform user wallet within seven days.

          <br />5.10. Although Travella does its best to provide a minimum
          verification mechanism for users, our platform merely links you to
          others without our intervention. Hence, you are entirely responsible
          for all activities that occur under your account and your interaction
          with any user on our platform as we do not employ any user. They are
          independent service providers whom you have voluntarily chosen.

          <br />5.11. The service may only be used by you with your details and
          for your items for your purpose. You shall not send or deliver items
          to any third party.

          <br />5.12. You acknowledge that we are merely providing technical
          functionality and that you are solely responsible for your
          representations and discussions. Do not use our service for anything
          other than to find senders and dispatchers. We have no duty to monitor
          the details of your discussions or messages.

          <br />5.13. We reserve the right to introduce and regulate
          verification, user, use, and item categories from time to time.

          <br />5.14. Upon discovery of an issue or defect in a transaction,
          item, or delivery, kindly contact Travella Customer Service on
          +2349068699900 or send us an email within 24 hours to
          support@travella.io. You may also visit us at House 5, Stateline
          Layout, futa South gate, Akure Ondo State, Nigeria. Upon your
          complaint, Travella reserves the right to request for the item for
          inspection pending the resolution of the issue.

          <br />5.15. You hereby agree that all disputes shall first be resolved
          by mediation. You further agree that where we are found to be at
          fault, our maximum aggregate liability in any manner whatsoever shall
          in no circumstances exceed the value limit stated above plus the
          amount paid by you to us in respect of the transaction in question.
          Where any part of this agreement is invalidated, only that
          (invalidated) part shall be affected.

          <br />5.16. You agree that all decisions and information you make
          while using our service are your full responsibility. You shall not
          disclose secret personal details and you shall secure same from the
          reach of third parties. If you detect or become aware of any
          unauthorized activity or use of your account or password on Travella,
          you shall immediately notify Travella via its customer contact number
          2349068699900 or by email: support@travella.io.

          <br />
          5.17. If you wish to send packages or items using Travella the
          following shall apply to you:

          <br />
          <span style="margin-left: 1.5rem"
            >5.17.4. You shall make an honest request on our platform disclosing
            all relevant details including the value of the item and you shall
            allow the dispatcher/traveler to inspect the item you wish to
            deliver.</span
          >

          <br />
          <span style="margin-left: 1.5rem"
            >5.17.5. You undertake not to request for the delivery of
            confidential information or items and if you must deliver such, you
            hereby undertake that they will not be considered confidential in
            any way whatsoever.
          </span>

          <br />
          <span style="margin-left: 1.5rem"
            >5.17.6. You shall not send or deliver items worth more than
            N100,000 (One Hundred Thousand Naira) – hereafter “value limit”. You
            hereby grant Travella unconditional exclusion and immunity where you
            attempt to deliver items worth more than the above value limit.
          </span>

          <br />
          <span style="margin-left: 1.5rem"
            >5.17.7. You hereby undertake not to send or deliver documents,
            certificates, or letters. If you must deliver a document or
            certificate using Travella, you undertake that the maximum liability
            we can bear will be the cost of a refund for the payment made to us
            irrespective of whatever happens to the document or its handling.
          </span>

          <br />
          <span style="margin-left: 1.5rem"
            >5.17.8. You warrant that you are fully aware of the laws of your
            domicile, the law of your residence, and the law of the land or
            local government where you intend/request to deliver an item. You
            further warrant that you shall not deliver any item that will be
            prohibited or illegal under any of the laws stated above.
          </span>

          <br />
          <span style="margin-left: 1.5rem"
            >5.17.9. In addition to the foregoing paragraphs, you undertake not
            to deliver any of the following items:
          </span>

          <br />
          <span style="margin-left: 1.5rem"
            >5.17.10. Upon a request on our platform, we shall link you will
            with a relevant messenger/user. However, we shall be under no
            liability for any delay or failure to deliver the products within
            the estimated timescales.
          </span>

          <br />
          <span style="margin-left: 1.5rem"
            >5.17.11. You undertake to abide by the rules and to pay the amount
            for your requested service and authorize us to debit your card /
            financial wallet and further authorize us to access your contacts in
            cases of emergencies.
          </span>

          <br />
          <span style="margin-left: 1.5rem"
            >5.17.12. You agree that our platform(s) does not constitute an
            offer but merely an invitation to join the pool of users who request
            and/or deliver services.
          </span>

          <br />
          <span style="margin-left: 1.5rem"
            >5.17.13. You agree that the messengers shall not be required to
            provide a return copy or acknowledgment copy of service or delivery.
            A notification on the platform alone will suffice.
          </span>

          <br />
          <span style="margin-left: 1.5rem"
            >5.17.14. You shall provide an accurate, reachable, and valid phone
            number and address of the recipient. Where recipient is not
            reachable or is unable to take his delivery 30 minutes from the
            arrival of the messenger/dispatcher, the transaction shall be deemed
            completed and Travella shall not refund any payment for such
            service. In the foregoing situation, the dispatcher will be entitled
            to drop the package around the address provided or take the package
            back to the sender who will be requested to pay a return fee being
            70% of the original dispatch fee.
          </span>

          <br />
          <span style="margin-left: 1.5rem"
            >5.17.15. You will, at your sole cost and expense, defend, indemnify
            and hold Travella harmless from all claims, demands, suits, damages,
            costs, claims arising from our out reliance on the information
            provided by you / delivery of your goods where it is found to
            contravene any of the terms of this agreement.</span
          >

          <br />

          5.18. If you wish to move, deliver or carry packages or items for the
          user(s) on the Travella Platform, the following shall apply to you:
          <br />

          <span style="margin-left: 1.5rem">
            5.18.16. You shall ensure that all orders and payments are handled
            within the platform. Payment shall be made by terms as may be
            stipulated by our representatives from time to time.
          </span>

          <br />
          <span style="margin-left: 1.5rem"
            >5.18.17. You hereby undertake to promptly and diligently carry out
            the delivery/services by the platform request and terms. You shall
            not transfer or assign your obligations.
          </span>

          <br />
          <span style="margin-left: 1.5rem"
            >5.18.18. You shall always make yourself available, reachable, and
            identifiable for real-time tracking and updates to both Travella and
            any user that requested your service on the platform.
          </span>

          <br />
          <span style="margin-left: 1.5rem"
            >5.18.19. Upon gaining possession of an item or delivery, you shall
            ensure you reasonably inspect the content and worth to ascertain
            that it is not an illegal item and it is also not an item exceeding
            NGN100,000. You hereby undertake to be responsible for all
            liabilities and damages arising from the handling of any item in
            your possession.
          </span>

          <br />
          <span style="margin-left: 1.5rem"
            >5.18.20. You shall act in good faith at all times and further
            authorize us to access your contacts in cases of emergencies.
          </span>

          <br />
          <span style="margin-left: 1.5rem"
            >5.18.21. You undertake not to leave items or products with
            unverified recipients. You undertake to refund the full value of the
            goods in the event of a loss or default on your part. You hereby
            grant Travella the right to use every legal means available to
            recover any outstanding amounts, including recourse to your
            bankers.</span
          >

          <br /><br />
        </p>

        <h3>6. Consent to receive Communication.</h3>

        <p>
          6.19 By signing up or using our services, the User agrees to receive
          digital communications from Travella.<br /><br />
        </p>

        <h3>7. Acceptable use of Communication.</h3>

        <p>
          7.20 The User agrees to use the available Communication Services to
          voice any feedback, complaints, or requests to Travella before
          publishing anything related to Travella to any third party or the
          public.<br />

          7.21 The User agrees that when using the Services of Travella, the
          User will <b>not</b>:

          <br />
          <span style="margin-left: 1.5rem">
            7.21.21.1. Defame, abuse, harass, stalk, threaten, or otherwise
            violate the legal rights of Travella, its staff, or any other
            user(s).
          </span>

          <br />
          <span style="margin-left: 1.5rem">
            7.21.21.2. Publish, post, distribute, dispatch, send, deliver or
            disseminate any names, materials, or information that is considered
            inappropriate, illegal, prohibited, defamatory, infringing, obscene,
            or unlawful.
          </span>

          <br />
          <span style="margin-left: 1.5rem">
            7.21.21.3. Advertise, offer to sell, or offer to buy anything as an
            agent or distributor to Travella.
          </span>

          <br />
          <span style="margin-left: 1.5rem">
            7.21.21.4. Restrict or inhibit any other person or user from using
            the Services of Travella.
          </span>

          <br />
          <span style="margin-left: 1.5rem">
            7.21.21.5. Harvest or otherwise collect personally identifiable
            information about others, without consent.
          </span>

          <br />
          <span style="margin-left: 1.5rem">
            7.21.21.6. Violate any code of conduct to be formulated by Travella
            from time to time.</span
          ><br /><br />
        </p>

        <h3>8. Intellectual Property Rights.</h3>

        <p>
          8.22 Travella Brooks Limited retains all intellectual property rights
          over its products and services and without limitation: to its;
          software, business name, trademarks, images, text, graphics,
          illustrations, logos, service marks, copyrights, photographs, and all
          related intellectual property rights. Except as otherwise provided in
          these Terms, the User shall not engage in an unpermitted reproduction
          or utilization of the goodwill or intellectual property rights of
          Travella. All goodwill generated from the use of Travella’s trademarks
          is reserved for the use of Travella, exclusively.<br /><br />
        </p>

        <h3>9. Copyright Infringement.</h3>

        <p>
          9.23 If the User believes that any material on the Site infringes upon
          any copyright owned and/or controlled by the User, the User may send a
          notice of alleged infringement to the contact information provided in
          these
          <router-link class="link" :to="{ name: 'Policy' }"
            ><b>terms and conditions</b> </router-link
          >.<br /><br />
        </p>

        <h3>10. Disclaimer of Responsibility and Liability.</h3>

        <p>
          10.24 THE USER AGREES THAT; IN NO EVENT WILL TRAVELLA AND/OR ITS
          AFFILIATES BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE,
          EXEMPLARY, OR CONSEQUENTIAL DAMAGES OR ANY LOSS OF USE, DATA,
          BUSINESS, OR PROFITS, REGARDLESS OF LEGAL THEORY. THE USER FURTHER
          WARRANTS THAT UNDER NO CIRCUMSTANCE SHALL THE LIABILITY OF TRAVELLA
          EXCEED THE VALUE LIMIT PLUS THE AMOUNT PAID BY THE USER
          NOTWITHSTANDING ANY CLAIM.<br /><br />
        </p>

        <h3>11. Release and Indemnity.</h3>

        <p>
          11.25. The User personally and on behalf of the User’s heirs,
          executors, agents, representatives, and assigns, fully release,
          defends, discharges, and holds Travella, and/or its affiliates,
          harmless - to the extent permitted by law - from any losses, damages,
          expenses, claims, and actions of any kind arising out of or relating
          to the use of Travella’s Services.<br /><br />
        </p>

        <h3>12. Dispute Resolution.</h3>

        <p>
          12.26. Disputes by the User should be first handled using the Travella
          resolution center. User concerns are first addressed by calling our
          customer care line on +2349068699900 or sending an email to
          support@travella.io or visiting our address at No 5, Stateline Layout,
          Futa South gate, Akure, Ondo State, Nigeria<br />
        </p>

        <p>
          12.27. However, if Travella is not able to resolve a dispute with the
          User within two weeks after attempts to do so informally, then as a
          condition to using of the services, the User agrees with Travella that
          such disputes, claims, rights arising thereof shall be resolved
          through binding mediation before a sole mediator to be appointed by
          both parties. The proceedings shall be held in Lagos, Nigeria, or any
          other location as to which the Travella and the User may agree in
          writing.<br />
        </p>

        <p>
          12.28. The cost of dispute resolution shall be borne by the party
          instituting the same. Any party seeking mediation must first send a
          written notice of dispute. Any notice to Travella should be addressed
          to the email address stated earlier but where it involves the
          commencement of dispute resolution, it should be addressed to
          Travella’s physical address stated earlier.<br />
        </p>

        <p>
          12.29. Any notice to be sent by Travella to the User may be sent to
          the User’s email address or phone number provided during the sign-up
          process.<br />
        </p>

        <p>
          12.30. Any cause of action concerning the service or related to
          Travella must be instituted within one year after the claim or cause
          of action has risen or been barred.<br /><br />
        </p>

        <h3>13. Applicable Law.</h3>

        <p>
          These Terms shall and will be governed by the Laws of the Federal
          Republic of Nigeria which shall for all intent and purposes be the
          governing law of this Terms.<br /><br />
        </p>

        <h3>14. Entire Agreement.</h3>

        <p>
          14.31. These Terms constitute the entire agreement between the User
          and Travella and its representatives concerning its subject matter and
          supersede and replace any other prior or contemporaneous agreements,
          or
          <router-link class="link" :to="{ name: 'Policy' }"
            ><b>terms and conditions</b>
          </router-link>
          applicable to the subject matter of this Terms. Provided that these
          Terms shall in no way be construed as creating third-party beneficiary
          rights.<br /><br />
        </p>

        <h3>15. Waiver, Severability, and Assignment.</h3>

        <p>
          15.32. The failure of Travella to enforce any provision in this Terms
          is not and shall not be construed as a waiver of its right to do so at
          a later time.<br />
        </p>

        <p>
          15.33. If any provision of these Terms is found unenforceable, the
          remaining provisions of the Terms will remain in full effect while the
          infringing term shall be amended to reflect the intent of Travella as
          nearly as possible.<br />
        </p>

        <p>
          15.34. The User may not assign any rights under these Terms, and any
          such attempt to do so shall be void and of no effect. Provided that
          Travella and its privies shall reserve the right to assign its rights
          to any of its affiliates or subsidiaries, or any successor in the
          interest of any business associated with the Services.<br /><br />
        </p>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped></style>
