<template>
  <div class="bd-container contact__box">
    <div class="contact__container address__container padding">
      <div class="contact__left">
        <div class="contact__head">
          Have a question or feedback? Get in touch with us, we can't wait to
          hear from you.
        </div>
        <div class="contact__subhead">
          Fill up the form and our team will get back to within 24 hrs
        </div>
      </div>
      <div class="contact__right show">
        <div class="contact__info">
          <div class="contact__location">
            <div class="location__title">
              Location
            </div>
            <div class="location__body">
              House 5, Green Garage, Stateline RD FUTA south gate, Akure, Ondo
              state, Nigeria.
            </div>
          </div>
          <div class="contact__credentials">
            <div class="location__title">
              Contact Us
            </div>
            <div class="location__body">
              +2349068699900 <br />
              info@travella.io
            </div>
          </div>
        </div>
      </div>
 
      <div class="address__form hide">
        <form class="register">
          <div class="input">
            <input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="First Name"
              v-model="firstName"
            />
          </div>
          <div class="input">
            <input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Last Name"
              v-model="lastName"
            />
          </div>
          <div class="input">
            <input
              type="text"
              name="email"
              placeholder="Email Address"
              id="email"
              v-model="email"
            />
          </div>
          <div class="input">
            <textarea
              rows="8"
              type="text"
              name="message"
              placeholder="Type message"
              id="message"
              v-model="message"
            />
          </div>
          <button @click.prevent="submit" class="contact-btn">Submit</button>
        </form>
      </div>
    </div>
    <div class="address__container contact__container mobile-padding">
      <div class="address__form show">
        <form class="register">
          <div class="input">
            <input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="First Name"
              v-model="firstName"
            />
          </div>
          <div class="input">
            <input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Last Name"
              v-model="lastName"
            />
          </div>
          <div class="input">
            <input
              type="text"
              name="email"
              placeholder="Email Address"
              id="email"
              v-model="email"
            />
          </div>
          <div class="input">
            <textarea
              rows="8"
              type="text"
              name="message"
              placeholder="Type message"
              id="message"
              v-model="message"
            />
          </div>
          <button @click.prevent="submit" class="contact-btn">Submit</button>
        </form>
      </div>
 
      <div class="contact__right hide">
        <div class="contact__info">
          <div class="contact__location">
            <div class="location__title">
              Location
            </div>
            <div class="location__body">
              House 5, Green Garage, Stateline RD FUTA south gate, Akure, Ondo
              state, Nigeria.
            </div>
          </div>
          <div class="contact__credentials">
            <div class="location__title">
              Contact Us
            </div>
            <div class="location__body">
              +2349068699900 <br />
              info@travella.io
            </div>
          </div>
        </div>
      </div>
 
      <div class="contact__map">
        <gmap-map :zoom="16" :center="center" style="height: 348px">
          <gmap-marker
            :key="index"
            v-for="(m, index) in locationMarkers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @click="center = m.position"
          ></gmap-marker>
        </gmap-map>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  data() {
    return {
      markers: [],
      firstName: "",
      lastName: "",
      email: "",
      message: "",
 
      center: {
        lat: 7.291008039175002,
        lng: 5.15256571717859,
      },
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
    };
  },
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  mounted() {
    this.locateGeoLocation();
  },
  methods: {
    locateGeoLocation: function() {
      this.locationMarkers.push({ position: this.center });
    },
    submit() {},
  },
};
</script>
 
<style lang="scss" scoped>
.contact__box {
  min-height: calc(100vh - 110px);
  padding: 1rem 0;
  @media screen and (min-width: 968px) {
    padding: 4rem 0;
  }
}
.padding {
  padding: 2rem 0;
}
.mobile-padding {
  @media screen and (max-width: 968px) {
    padding: 2rem 0;
  }
}
.address__container {
  display: grid;
 
  // padding: 8rem 0;
  align-items: start;
  grid-template-columns: repeat(1, 1fr);
  @media screen and (min-width: 968px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    // padding: 8rem 0;
  }
 
  .address__form {
    .input {
      margin-bottom: 10px;
 
      input,
      textarea {
        background: rgba(210, 218, 237, 0.17);
        border-radius: 4px;
        width: 100%;
        padding: 4px 4px 4px 10px;
        border: none;
      }
 
      input {
        height: 50px;
      }
    }
  }
}
.show {
  display: none;
  @media screen and (min-width: 968px) {
    display: block;
  }
}
.hide {
  display: block;
  @media screen and (min-width: 968px) {
    display: none;
  }
}
.contact__container {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(1, 1fr);
  @media screen and (min-width: 968px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    // padding: 8rem 0;
  }
 
  .contact__head {
    font-weight: var(--font-semi-bold);
    line-height: 54px;
    font-size: var(--large-font-size);
    color: #1d3444;
  }
  .contact__subhead {
    margin: 1.5rem 0;
  }
 
  .contact__info {
    display: grid;
    row-gap: 2rem;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    // padding: 8rem 0;
    @media screen and (min-width: 968px) {
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      //   padding: 8rem 0;
    }
    .location__title {
      font-size: 20px;
      font-weight: var(--font-semi-bold);
      margin-bottom: 1rem;
      line-height: 25px;
    }
  }
}
</style>
 

