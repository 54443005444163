import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vue2Editor from "vue2-editor";
import axios from "axios";
import VueTyperPlugin  from 'vue-typer'
import VueSpinners from 'vue-spinners'
import * as VueGoogleMaps from "vue2-google-maps";
// import app from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

Vue.prototype.$http = axios;

Vue.use(Vue2Editor);

Vue.use(VueSpinners)

Vue.use(VueTyperPlugin)

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAAEcOY98veCESLeA_jchHtkPzmDPO1d-w",
    libraries: "places",
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
