<template>
  <div class="form-wrap">
    <form class="register">
      <div class="login-register">
        <router-link :to="{ name: 'Home' }">
          <img :src="logo" />
        </router-link>
      </div>
      <h2 style="font-weight: 500">Carefully input the following details</h2>

      <div class="inputs">
        <div class="input">
          <label for="firstName">First Name</label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            v-model="first_name"
          />
        </div>
        <div class="input">
          <label for="lastName"> Last Name</label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            v-model="last_name"
          />
        </div>
        <div class="input">
          <label for="email">Business Email Address</label>
          <input type="text" name="email" id="email" v-model="business_email" />
        </div>
        <div class="input">
          <label for="address">Business Address</label>
          <input
            type="text"
            name="address"
            id="biz_address"
            v-model="business_address"
          />
        </div>
        <div class="input">
          <label for="phoneNumber">Business Number</label>

          <span class="num" style="width: 30%; padding-right: 10px">
            <select
              class="countrysel"
              name="countryCode"
              style="margin-right: 10px"
              id="countrysel"
              v-model="country"
            >
              <!-- <option data-countryCode="NG" :value="country" Selected>
                {{ country }}
              </option> -->
              <option data-countryCode="GB" value="+44">Norway (+47)</option>
              <option data-countryCode="US" value="+1">UK (+44)</option>
              <option data-countryCode="DZ" value="+213">Algeria (+213)</option>
              <option data-countryCode="AD" value="+376">Andorra (+376)</option>
              <option data-countryCode="AO" value="+244">Angola (+244)</option>
              <option data-countryCode="AI" value="+1264">
                Anguilla (+1264)
              </option>
              <option data-countryCode="AG" value="+1268">
                Antigua &amp; Barbuda (+1268)
              </option>
              <option data-countryCode="AR" value="+54">Argentina (+54)</option>
              <option data-countryCode="AM" value="+374">Armenia (+374)</option>
              <option data-countryCode="AW" value="+297">Aruba (+297)</option>
              <option data-countryCode="AU" value="+61">Australia (+61)</option>
              <option data-countryCode="AT" value="+43">Austria (+43)</option>
              <option data-countryCode="AZ" value="+994">
                Azerbaijan (+994)
              </option>
              <option data-countryCode="BS" value="+1242">
                Bahamas (+1242)
              </option>
              <option data-countryCode="BH" value="+973">Bahrain (+973)</option>
              <option data-countryCode="BD" value="+880">
                Bangladesh (+880)
              </option>
              <option data-countryCode="BB" value="+1246">
                Barbados (+1246)
              </option>
              <option data-countryCode="BY" value="+375">Belarus (+375)</option>
              <option data-countryCode="BE" value="+32">Belgium (+32)</option>
              <option data-countryCode="BZ" value="+501">Belize (+501)</option>
              <option data-countryCode="BJ" value="+229">Benin (+229)</option>
              <option data-countryCode="BM" value="+1441">
                Bermuda (+1441)
              </option>
              <option data-countryCode="BT" value="+975">Bhutan (+975)</option>
              <option data-countryCode="BO" value="+591">Bolivia (+591)</option>
              <option data-countryCode="BA" value="+387">
                Bosnia Herzegovina (+387)
              </option>
              <option data-countryCode="BW" value="+267">
                Botswana (+267)
              </option>
              <option data-countryCode="BR" value="+55">Brazil (+55)</option>
              <option data-countryCode="BN" value="+673">Brunei (+673)</option>
              <option data-countryCode="BG" value="+359">
                Bulgaria (+359)
              </option>
              <option data-countryCode="BF" value="+226">
                Burkina Faso (+226)
              </option>
              <option data-countryCode="BI" value="+257">Burundi (+257)</option>
              <option data-countryCode="KH" value="+855">
                Cambodia (+855)
              </option>
              <option data-countryCode="CM" value="+237">
                Cameroon (+237)
              </option>
              <option data-countryCode="CA" value="+1">Canada (+1)</option>
              <option data-countryCode="CV" value="+238">
                Cape Verde Islands (+238)
              </option>
              <option data-countryCode="KY" value="+1345">
                Cayman Islands (+1345)
              </option>
              <option data-countryCode="CF" value="+236">
                Central African Republic (+236)
              </option>
              <option data-countryCode="CL" value="+56">Chile (+56)</option>
              <option data-countryCode="CN" value="+86">China (+86)</option>
              <option data-countryCode="CO" value="+57">Colombia (+57)</option>
              <option data-countryCode="KM" value="+269">Comoros (+269)</option>
              <option data-countryCode="CG" value="+242">Congo (+242)</option>
              <option data-countryCode="CK" value="+682">
                Cook Islands (+682)
              </option>
              <option data-countryCode="CR" value="+506">
                Costa Rica (+506)
              </option>
              <option data-countryCode="HR" value="+385">Croatia (+385)</option>
              <option data-countryCode="CU" value="+53">Cuba (+53)</option>
              <option data-countryCode="CY" value="+90392">
                Cyprus North (+90392)
              </option>
              <option data-countryCode="CY" value="+357">
                Cyprus South (+357)
              </option>
              <option data-countryCode="CZ" value="+42">
                Czech Republic (+42)
              </option>
              <option data-countryCode="DK" value="+45">Denmark (+45)</option>
              <option data-countryCode="DJ" value="+253">
                Djibouti (+253)
              </option>
              <option data-countryCode="DM" value="+1809">
                Dominica (+1809)
              </option>
              <option data-countryCode="DO" value="+1809">
                Dominican Republic (+1809)
              </option>
              <option data-countryCode="EC" value="+593">Ecuador (+593)</option>
              <option data-countryCode="EG" value="+20">Egypt (+20)</option>
              <option data-countryCode="SV" value="+503">
                El Salvador (+503)
              </option>
              <option data-countryCode="GQ" value="+240">
                Equatorial Guinea (+240)
              </option>
              <option data-countryCode="ER" value="+291">Eritrea (+291)</option>
              <option data-countryCode="EE" value="+372">Estonia (+372)</option>
              <option data-countryCode="ET" value="+251">
                Ethiopia (+251)
              </option>
              <option data-countryCode="FK" value="+500">
                Falkland Islands (+500)
              </option>
              <option data-countryCode="FO" value="+298">
                Faroe Islands (+298)
              </option>
              <option data-countryCode="FJ" value="+679">Fiji (+679)</option>
              <option data-countryCode="FI" value="+358">Finland (+358)</option>
              <option data-countryCode="FR" value="+33">France (+33)</option>
              <option data-countryCode="GF" value="+594">
                French Guiana (+594)
              </option>
              <option data-countryCode="PF" value="+689">
                French Polynesia (+689)
              </option>
              <option data-countryCode="GA" value="+241">Gabon (+241)</option>
              <option data-countryCode="GM" value="+220">Gambia (+220)</option>
              <option data-countryCode="GE" value="+7880">
                Georgia (+7880)
              </option>
              <option data-countryCode="DE" value="+49">Germany (+49)</option>
              <option data-countryCode="GH" value="+233">Ghana (+233)</option>
              <option data-countryCode="GI" value="+350">
                Gibraltar (+350)
              </option>
              <option data-countryCode="GR" value="+30">Greece (+30)</option>
              <option data-countryCode="GL" value="+299">
                Greenland (+299)
              </option>
              <option data-countryCode="GD" value="+1473">
                Grenada (+1473)
              </option>
              <option data-countryCode="GP" value="+590">
                Guadeloupe (+590)
              </option>
              <option data-countryCode="GU" value="+671">Guam (+671)</option>
              <option data-countryCode="GT" value="+502">
                Guatemala (+502)
              </option>
              <option data-countryCode="GN" value="+224">Guinea (+224)</option>
              <option data-countryCode="GW" value="+245">
                Guinea - Bissau (+245)
              </option>
              <option data-countryCode="GY" value="+592">Guyana (+592)</option>
              <option data-countryCode="HT" value="+509">Haiti (+509)</option>
              <option data-countryCode="HN" value="+504">
                Honduras (+504)
              </option>
              <option data-countryCode="HK" value="+852">
                Hong Kong (+852)
              </option>
              <option data-countryCode="HU" value="+36">Hungary (+36)</option>
              <option data-countryCode="IS" value="+354">Iceland (+354)</option>
              <option data-countryCode="IN" value="+91">India (+91)</option>
              <option data-countryCode="ID" value="+62">Indonesia (+62)</option>
              <option data-countryCode="IR" value="+98">Iran (+98)</option>
              <option data-countryCode="IQ" value="+964">Iraq (+964)</option>
              <option data-countryCode="IE" value="+353">Ireland (+353)</option>
              <option data-countryCode="IL" value="+972">Israel (+972)</option>
              <option data-countryCode="IT" value="+39">Italy (+39)</option>
              <option data-countryCode="JM" value="+1876">
                Jamaica (+1876)
              </option>
              <option data-countryCode="JP" value="+81">Japan (+81)</option>
              <option data-countryCode="JO" value="+962">Jordan (+962)</option>
              <option data-countryCode="KZ" value="+7">Kazakhstan (+7)</option>
              <option data-countryCode="KE" value="+254">Kenya (+254)</option>
              <option data-countryCode="KI" value="+686">
                Kiribati (+686)
              </option>
              <option data-countryCode="KP" value="+850">
                Korea North (+850)
              </option>
              <option data-countryCode="KR" value="+82">
                Korea South (+82)
              </option>
              <option data-countryCode="KW" value="+965">Kuwait (+965)</option>
              <option data-countryCode="KG" value="+996">
                Kyrgyzstan (+996)
              </option>
              <option data-countryCode="LA" value="+856">Laos (+856)</option>
              <option data-countryCode="LV" value="+371">Latvia (+371)</option>
              <option data-countryCode="LB" value="+961">Lebanon (+961)</option>
              <option data-countryCode="LS" value="+266">Lesotho (+266)</option>
              <option data-countryCode="LR" value="+231">Liberia (+231)</option>
              <option data-countryCode="LY" value="+218">Libya (+218)</option>
              <option data-countryCode="LI" value="+417">
                Liechtenstein (+417)
              </option>
              <option data-countryCode="LT" value="+370">
                Lithuania (+370)
              </option>
              <option data-countryCode="LU" value="+352">
                Luxembourg (+352)
              </option>
              <option data-countryCode="MO" value="+853">Macao (+853)</option>
              <option data-countryCode="MK" value="+389">
                Macedonia (+389)
              </option>
              <option data-countryCode="MG" value="+261">
                Madagascar (+261)
              </option>
              <option data-countryCode="MW" value="+265">Malawi (+265)</option>
              <option data-countryCode="MY" value="+60">Malaysia (+60)</option>
              <option data-countryCode="MV" value="+960">
                Maldives (+960)
              </option>
              <option data-countryCode="ML" value="+223">Mali (+223)</option>
              <option data-countryCode="MT" value="+356">Malta (+356)</option>
              <option data-countryCode="MH" value="+692">
                Marshall Islands (+692)
              </option>
              <option data-countryCode="MQ" value="+596">
                Martinique (+596)
              </option>
              <option data-countryCode="MR" value="+222">
                Mauritania (+222)
              </option>
              <option data-countryCode="YT" value="+269">Mayotte (+269)</option>
              <option data-countryCode="MX" value="+52">Mexico (+52)</option>
              <option data-countryCode="FM" value="+691">
                Micronesia (+691)
              </option>
              <option data-countryCode="MD" value="+373">Moldova (+373)</option>
              <option data-countryCode="MC" value="+377">Monaco (+377)</option>
              <option data-countryCode="MN" value="+976">
                Mongolia (+976)
              </option>
              <option data-countryCode="MS" value="+1664">
                Montserrat (+1664)
              </option>
              <option data-countryCode="MA" value="+212">Morocco (+212)</option>
              <option data-countryCode="MZ" value="+258">
                Mozambique (+258)
              </option>
              <option data-countryCode="MN" value="+95">Myanmar (+95)</option>
              <option data-countryCode="NA" value="+264">Namibia (+264)</option>
              <option data-countryCode="NR" value="+674">Nauru (+674)</option>
              <option data-countryCode="NP" value="+977">Nepal (+977)</option>
              <option data-countryCode="NL" value="+31">
                Netherlands (+31)
              </option>
              <option data-countryCode="NC" value="+687">
                New Caledonia (+687)
              </option>
              <option data-countryCode="NZ" value="+64">
                New Zealand (+64)
              </option>
              <option data-countryCode="NI" value="+505">
                Nicaragua (+505)
              </option>
              <option data-countryCode="NE" value="+227">Niger (+227)</option>
              <option data-countryCode="NG" value="+234" Selected>
                Nigeria (+234)
              </option>
              <option data-countryCode="NU" value="+683">Niue (+683)</option>
              <option data-countryCode="NF" value="+672">
                Norfolk Islands (+672)
              </option>
              <option data-countryCode="NP" value="+670">
                Northern Marianas (+670)
              </option>
              <option data-countryCode="NO" value="+47">Norway (+47)</option>
              <option data-countryCode="OM" value="+968">Oman (+968)</option>
              <option data-countryCode="PW" value="+680">Palau (+680)</option>
              <option data-countryCode="PA" value="+507">Panama (+507)</option>
              <option data-countryCode="PG" value="+675">
                Papua New Guinea (+675)
              </option>
              <option data-countryCode="PY" value="+595">
                Paraguay (+595)
              </option>
              <option data-countryCode="PE" value="+51">Peru (+51)</option>
              <option data-countryCode="PH" value="+63">
                Philippines (+63)
              </option>
              <option data-countryCode="PL" value="+48">Poland (+48)</option>
              <option data-countryCode="PT" value="+351">
                Portugal (+351)
              </option>
              <option data-countryCode="PR" value="+1787">
                Puerto Rico (+1787)
              </option>
              <option data-countryCode="QA" value="+974">Qatar (+974)</option>
              <option data-countryCode="RE" value="+262">Reunion (+262)</option>
              <option data-countryCode="RO" value="+40">Romania (+40)</option>
              <option data-countryCode="RU" value="+7">Russia (+7)</option>
              <option data-countryCode="RW" value="+250">Rwanda (+250)</option>
              <option data-countryCode="SM" value="+378">
                San Marino (+378)
              </option>
              <option data-countryCode="ST" value="+239">
                Sao Tome &amp; Principe (+239)
              </option>
              <option data-countryCode="SA" value="+966">
                Saudi Arabia (+966)
              </option>
              <option data-countryCode="SN" value="+221">Senegal (+221)</option>
              <option data-countryCode="CS" value="+381">Serbia (+381)</option>
              <option data-countryCode="SC" value="+248">
                Seychelles (+248)
              </option>
              <option data-countryCode="SL" value="+232">
                Sierra Leone (+232)
              </option>
              <option data-countryCode="SG" value="+65">Singapore (+65)</option>
              <option data-countryCode="SK" value="+421">
                Slovak Republic (+421)
              </option>
              <option data-countryCode="SI" value="+386">
                Slovenia (+386)
              </option>
              <option data-countryCode="SB" value="+677">
                Solomon Islands (+677)
              </option>
              <option data-countryCode="SO" value="+252">Somalia (+252)</option>
              <option data-countryCode="ZA" value="+27">
                South Africa (+27)
              </option>
              <option data-countryCode="ES" value="+34">Spain (+34)</option>
              <option data-countryCode="LK" value="+94">Sri Lanka (+94)</option>
              <option data-countryCode="SH" value="+290">
                St. Helena (+290)
              </option>
              <option data-countryCode="KN" value="+1869">
                St. Kitts (+1869)
              </option>
              <option data-countryCode="SC" value="+1758">
                St. Lucia (+1758)
              </option>
              <option data-countryCode="SD" value="+249">Sudan (+249)</option>
              <option data-countryCode="SR" value="+597">
                Suriname (+597)
              </option>
              <option data-countryCode="SZ" value="+268">
                Swaziland (+268)
              </option>
              <option data-countryCode="SE" value="+46">Sweden (+46)</option>
              <option data-countryCode="CH" value="+41">
                Switzerland (+41)
              </option>
              <option data-countryCode="SI" value="+963">Syria (+963)</option>
              <option data-countryCode="TW" value="+886">Taiwan (+886)</option>
              <option data-countryCode="TJ" value="+7">Tajikstan (+7)</option>
              <option data-countryCode="TH" value="+66">Thailand (+66)</option>
              <option data-countryCode="TG" value="+228">Togo (+228)</option>
              <option data-countryCode="TO" value="+676">Tonga (+676)</option>
              <option data-countryCode="TT" value="+1868">
                Trinidad &amp; Tobago (+1868)
              </option>
              <option data-countryCode="TN" value="+216">Tunisia (+216)</option>
              <option data-countryCode="TR" value="+90">Turkey (+90)</option>
              <option data-countryCode="TM" value="+7">
                Turkmenistan (+7)
              </option>
              <option data-countryCode="TM" value="+993">
                Turkmenistan (+993)
              </option>
              <option data-countryCode="TC" value="+1649">
                Turks &amp; Caicos Islands (+1649)
              </option>
              <option data-countryCode="TV" value="+688">Tuvalu (+688)</option>
              <option data-countryCode="UG" value="+256">Uganda (+256)</option>
              <!-- <option data-countryCode="GB" value="+44">UK (+44)</option> -->
              <option data-countryCode="UA" value="+380">Ukraine (+380)</option>
              <option data-countryCode="AE" value="+971">
                United Arab Emirates (+971)
              </option>
              <option data-countryCode="UY" value="+598">Uruguay (+598)</option>
              <!-- <option data-countryCode="US" value="+1">USA (+1)</option> -->
              <option data-countryCode="UZ" value="+7">Uzbekistan (+7)</option>
              <option data-countryCode="VU" value="+678">Vanuatu (+678)</option>
              <option data-countryCode="VA" value="+379">
                Vatican City (+379)
              </option>
              <option data-countryCode="VE" value="+58">Venezuela (+58)</option>
              <option data-countryCode="VN" value="+84">Vietnam (+84)</option>
              <option data-countryCode="VG" value="+84">
                Virgin Islands - British (+1284)
              </option>
              <option data-countryCode="VI" value="+84">
                Virgin Islands - US (+1340)
              </option>
              <option data-countryCode="WF" value="+681">
                Wallis &amp; Futuna (+681)
              </option>
              <option data-countryCode="YE" value="+969">
                Yemen (North)(+969)
              </option>
              <option data-countryCode="YE" value="+967">
                Yemen (South)(+967)
              </option>
              <option data-countryCode="ZM" value="+260">Zambia (+260)</option>
              <option data-countryCode="ZW" value="+263">
                Zimbabwe (+263)
              </option>
            </select></span
          >
          <input
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            style="width: 70%"
            v-model="business_number"
          />
        </div>
        <div class="input">
          <label for="address">Business Name</label>
          <input
            type="text"
            name="address"
            id="biz_name"
            v-model="business_name"
          />
        </div>
        <div class="input">
          <label for="address">Business Address</label>
          <input
            type="text"
            name="address"
            id="address"
            v-model="business_address"
          />
        </div>
        <div class="input">
          <label for="country">Operation Country</label>
          <span class="drp">
            <select
              class="countrysel"
              name="countryCode"
              id="op_country"
              v-model="operation_country"
              @change="filterCity"
            >
              <!-- <select name="countryCode" id=""> -->
              <option data-countryCode="GB" value="Norway">Norway</option>
              <option data-countryCode="US" value="United Kingdom">UK</option>
              <option data-countryCode="DZ" value="Algeria">Algeria</option>
              <option data-countryCode="AD" value="Andorra">Andorra</option>
              <option data-countryCode="AO" value="Angola">Angola</option>
              <option data-countryCode="AI" value="Anguilla">Anguilla</option>
              <option data-countryCode="AG" value="Antigua Barbuda">
                Antigua &amp; Barbuda
              </option>
              <option data-countryCode="AR" value="Argentina">Argentina</option>
              <option data-countryCode="AM" value="Armenia">Armenia</option>
              <option data-countryCode="AW" value="Aruba">Aruba</option>
              <option data-countryCode="AU" value="Australia">Australia</option>
              <option data-countryCode="AT" value="Austria">Austria</option>
              <option data-countryCode="AZ" value="Azerbaijan">
                Azerbaijan
              </option>
              <option data-countryCode="BS" value="Bahamas">Bahamas</option>
              <option data-countryCode="BH" value="Bahrain">Bahrain</option>
              <option data-countryCode="BD" value="Bangladesh">
                Bangladesh
              </option>
              <option data-countryCode="BB" value="Barbados">Barbados</option>
              <option data-countryCode="BY" value="Belarus">Belarus</option>
              <option data-countryCode="BE" value="Belgium">Belgium</option>
              <option data-countryCode="BZ" value="Belize">Belize</option>
              <option data-countryCode="BJ" value="Benin">Benin</option>
              <option data-countryCode="BM" value="Bermuda">Bermuda</option>
              <option data-countryCode="BT" value="Bhutan">Bhutan</option>
              <option data-countryCode="BO" value="Bolivia">Bolivia</option>
              <option data-countryCode="BA" value="Bosnia Herzegovina">
                Bosnia Herzegovina
              </option>
              <option data-countryCode="BW" value="Botswana">Botswana</option>
              <option data-countryCode="BR" value="Brazil">Brazil</option>
              <option data-countryCode="BN" value="Brunei">Brunei</option>
              <option data-countryCode="BG" value="Bulgaria">Bulgaria</option>
              <option data-countryCode="BF" value="Burkina Faso">
                Burkina Faso
              </option>
              <option data-countryCode="BI" value="Burundi">Burundi</option>
              <option data-countryCode="KH" value="Cambodia">Cambodia</option>
              <option data-countryCode="CM" value="Cameroon">Cameroon</option>
              <option data-countryCode="CA" value="Canada">Canada</option>
              <option data-countryCode="CV" value="Cape Verde Islands">
                Cape Verde Islands
              </option>
              <option data-countryCode="KY" value="Cayman Islands">
                Cayman Islands
              </option>
              <option data-countryCode="CF" value="Central African Republic">
                Central African Republic
              </option>
              <option data-countryCode="CL" value="Chile">Chile</option>
              <option data-countryCode="CN" value="China">China</option>
              <option data-countryCode="CO" value="Colombia">Colombia</option>
              <option data-countryCode="KM" value="Comoros">Comoros</option>
              <option data-countryCode="CG" value="Congo">Congo</option>
              <option data-countryCode="CK" value="Cook Islands">
                Cook Islands
              </option>
              <option data-countryCode="CR" value="Costa Rica">
                Costa Rica
              </option>
              <option data-countryCode="HR" value="Croatia">Croatia</option>
              <option data-countryCode="CU" value="Cuba">Cuba</option>
              <option data-countryCode="CY" value="Cyprus North">
                Cyprus North
              </option>
              <option data-countryCode="CY" value="Cyprus South">
                Cyprus South
              </option>
              <option data-countryCode="CZ" value="Czech Republic">
                Czech Republic
              </option>
              <option data-countryCode="DK" value="Denmark">Denmark</option>
              <option data-countryCode="DJ" value="Djibouti">Djibouti</option>
              <option data-countryCode="DM" value="Dominica">Dominica</option>
              <option data-countryCode="DO" value="Dominican Republic">
                Dominican Republic
              </option>
              <option data-countryCode="EC" value="Djibouti">Djibouti</option>
              <option data-countryCode="EG" value="Egypt">Egypt</option>
              <option data-countryCode="SV" value="El Salvador">
                El Salvador
              </option>
              <option data-countryCode="GQ" value="Equatorial Guinea">
                Equatorial Guinea
              </option>
              <option data-countryCode="ER" value="Eritrea">Eritrea</option>
              <option data-countryCode="EE" value="Estonia">Estonia</option>
              <option data-countryCode="ET" value="Ethiopia">Ethiopia</option>
              <option data-countryCode="FK" value="Falkland Islands">
                Falkland Islands
              </option>
              <option data-countryCode="FO" value="Faroe Islands">
                Faroe Islands
              </option>
              <option data-countryCode="FJ" value="Fiji">Fiji</option>
              <option data-countryCode="FI" value="Finland">Finland</option>
              <option data-countryCode="FR" value="France">France</option>
              <option data-countryCode="GF" value="French Guiana">
                French Guiana
              </option>
              <option data-countryCode="PF" value="French Polynesia">
                French Polynesia
              </option>
              <option data-countryCode="GA" value="Gabon">Gabon</option>
              <option data-countryCode="GM" value="Gambia">Gambia</option>
              <option data-countryCode="GE" value="Georgia">Georgia</option>
              <option data-countryCode="DE" value="Germany">Germany</option>
              <option data-countryCode="GH" value="Ghana">Ghana</option>
              <option data-countryCode="GI" value="Gibraltar">Gibraltar</option>
              <option data-countryCode="GR" value="Greece">Greece</option>
              <option data-countryCode="GL" value="Greenland">Greenland</option>
              <option data-countryCode="GD" value="Grenada">Grenada</option>
              <option data-countryCode="GP" value="Guadeloupe">
                Guadeloupe
              </option>
              <option data-countryCode="GU" value="Guam">Guam</option>
              <option data-countryCode="GT" value="Guatemala">Guatemala</option>
              <option data-countryCode="GN" value="Guinea">Guinea</option>
              <option data-countryCode="GW" value="Guinea - Bissau">
                Guinea - Bissau
              </option>
              <option data-countryCode="GY" value="Guyana">Guyana</option>
              <option data-countryCode="HT" value="Haiti">Haiti</option>
              <option data-countryCode="HN" value="Honduras">Honduras</option>
              <option data-countryCode="HK" value="Hong Kong">Hong Kong</option>
              <option data-countryCode="HU" value="Hungary">Hungary</option>
              <option data-countryCode="IS" value="Iceland">Iceland</option>
              <option data-countryCode="IN" value="India">India</option>
              <option data-countryCode="ID" value="Indonesia">Indonesia</option>
              <option data-countryCode="IR" value="Iran">Iran</option>
              <option data-countryCode="IQ" value="Iraq">Iraq</option>
              <option data-countryCode="IE" value="Ireland">Ireland</option>
              <option data-countryCode="IL" value="Israel">Israel</option>
              <option data-countryCode="IT" value="Italy">Italy</option>
              <option data-countryCode="JM" value="Jamaica">Jamaica</option>
              <option data-countryCode="JP" value="Japan">Japan</option>
              <option data-countryCode="JO" value="Jordan">Jordan</option>
              <option data-countryCode="KZ" value="Kazakhstan">
                Kazakhstan
              </option>
              <option data-countryCode="KE" value="Kenya">Kenya</option>
              <option data-countryCode="KI" value="Kiribati">Kiribati</option>
              <option data-countryCode="KP" value="Korea North">
                Korea North
              </option>
              <option data-countryCode="KR" value="Korea South">
                Korea South
              </option>
              <option data-countryCode="KW" value="Kuwait">Kuwait</option>
              <option data-countryCode="KG" value="Kyrgyzstan">
                Kyrgyzstan
              </option>
              <option data-countryCode="LA" value="Laos">Laos</option>
              <option data-countryCode="LV" value="Latvia">Latvia</option>
              <option data-countryCode="LB" value="Lebanon">Lebanon</option>
              <option data-countryCode="LS" value="Lesotho">Lesotho</option>
              <option data-countryCode="LR" value="Liberia">Liberia</option>
              <option data-countryCode="LY" value="Libya">Libya</option>
              <option data-countryCode="LI" value="Liechtenstein">
                Liechtenstein
              </option>
              <option data-countryCode="LT" value="Lithuania">Lithuania</option>
              <option data-countryCode="LU" value="Luxembourg">
                Luxembourg
              </option>
              <option data-countryCode="MO" value="Macao">Macao</option>
              <option data-countryCode="MK" value="Macedonia">Macedonia</option>
              <option data-countryCode="MG" value="Madagascar">
                Madagascar
              </option>
              <option data-countryCode="MW" value="Malawi">Malawi</option>
              <option data-countryCode="MY" value="Malaysia">Malaysia</option>
              <option data-countryCode="MV" value="Maldives">Maldives</option>
              <option data-countryCode="ML" value="Mali">Mali</option>
              <option data-countryCode="MT" value="Malta">Malta</option>
              <option data-countryCode="MH" value="Marshall Islands">
                Marshall Island
              </option>
              <option data-countryCode="MQ" value="Martinique">
                Martinique
              </option>
              <option data-countryCode="MR" value="Mauritania">
                Mauritania
              </option>
              <option data-countryCode="YT" value="Mayotte">Mayotte</option>
              <option data-countryCode="MX" value="Mexico">Mexico</option>
              <option data-countryCode="FM" value="Micronesia">
                Micronesia
              </option>
              <option data-countryCode="MD" value="Moldova">Moldova</option>
              <option data-countryCode="MC" value="Monaco">Monaco</option>
              <option data-countryCode="MN" value="Mongolia">Mongolia</option>
              <option data-countryCode="MS" value="Montserrat">
                Montserrat
              </option>
              <option data-countryCode="MA" value="Morocco">Morocco</option>
              <option data-countryCode="MZ" value="Mozambique">
                Mozambique
              </option>
              <option data-countryCode="MN" value="Myanmar">Myanmar</option>
              <option data-countryCode="NA" value="Namibia">Namibia</option>
              <option data-countryCode="NR" value="Nauru">Nauru</option>
              <option data-countryCode="NP" value="Nepal">Nepal</option>
              <option data-countryCode="NL" value="Netherlands">
                Netherlands
              </option>
              <option data-countryCode="NC" value="New Caledonia">
                New Caledonia
              </option>
              <option data-countryCode="NZ" value="New Zealand">
                New Zealand
              </option>
              <option data-countryCode="NI" value="Nicaragua">Nicaragua</option>
              <option data-countryCode="NE" value="Niger">Niger</option>
              <option data-countryCode="NG" value="Nigeria" Selected>
                Nigeria
              </option>
              <option data-countryCode="NU" value="Niue">Niue</option>
              <option data-countryCode="NF" value="Norfolk Islands">
                Norfolk Islands
              </option>
              <option data-countryCode="NP" value="Northern Marianas">
                Northern Marianas
              </option>
              <option data-countryCode="NO" value="Norway">Norway</option>
              <option data-countryCode="OM" value="Oman">Oman</option>
              <option data-countryCode="PW" value="Palau">Palau</option>
              <option data-countryCode="PA" value="Panama">Panama</option>
              <option data-countryCode="PG" value="Papua New Guinea">
                Papua New Guinea
              </option>
              <option data-countryCode="PY" value="Paraguay">Paraguay</option>
              <option data-countryCode="PE" value="Peru">Peru</option>
              <option data-countryCode="PH" value="Philippines">
                Philippines
              </option>
              <option data-countryCode="PL" value="Poland">Poland</option>
              <option data-countryCode="PT" value="Portugal">Portugal</option>
              <option data-countryCode="PR" value="Puerto Rico">
                Puerto Rico
              </option>
              <option data-countryCode="QA" value="Qatar">Qatar</option>
              <option data-countryCode="RE" value="Reunion">Reunion</option>
              <option data-countryCode="RO" value="Romania">Romania</option>
              <option data-countryCode="RU" value="Russia">Russia</option>
              <option data-countryCode="RW" value="Rwanda">Rwanda</option>
              <option data-countryCode="SM" value="San Marino">
                San Marino
              </option>
              <option data-countryCode="ST" value="Sao Tome Principe">
                Sao Tome &amp; Principe
              </option>
              <option data-countryCode="SA" value="Saudi Arabia">
                Saudi Arabia
              </option>
              <option data-countryCode="SN" value="Senegal">Senegal</option>
              <option data-countryCode="CS" value="Serbia">Serbia</option>
              <option data-countryCode="SC" value="Seychelles">
                Seychelles
              </option>
              <option data-countryCode="SL" value="Sierra Leone">
                Sierra Leone
              </option>
              <option data-countryCode="SG" value="Singapore">Singapore</option>
              <option data-countryCode="SK" value="Slovak Republic">
                Slovak Republic
              </option>
              <option data-countryCode="SI" value="Slovenia">Slovenia</option>
              <option data-countryCode="SB" value="Solomon Islands">
                Solomon Islands
              </option>
              <option data-countryCode="SO" value="Somalia">Somalia</option>
              <option data-countryCode="ZA" value="South Africa">
                South Africa
              </option>
              <option data-countryCode="ES" value="Spain">Spain</option>
              <option data-countryCode="LK" value="Sri Lanka">Sri Lanka</option>
              <option data-countryCode="SH" value="St. Helena">
                St. Helena
              </option>
              <option data-countryCode="KN" value="St. Kitts">St. Kitts</option>
              <option data-countryCode="SC" value="St. Lucia">St. Lucia</option>
              <option data-countryCode="SD" value="Sudan">Sudan</option>
              <option data-countryCode="SR" value="Suriname">Suriname</option>
              <option data-countryCode="SZ" value="Swaziland">Swaziland</option>
              <option data-countryCode="SE" value="Sweden">Sweden</option>
              <option data-countryCode="CH" value="Switzerland">
                Switzerland
              </option>
              <option data-countryCode="SI" value="Syria">Syria</option>
              <option data-countryCode="TW" value="Taiwan">Taiwan</option>
              <option data-countryCode="TJ" value="Tajikstan">Tajikstan</option>
              <option data-countryCode="TH" value="Thailand">Thailand</option>
              <option data-countryCode="TG" value="Togo">Togo</option>
              <option data-countryCode="TO" value="Tonga">Tonga</option>
              <option data-countryCode="TT" value="Trinidad Tobago">
                Trinidad &amp; Tobago
              </option>
              <option data-countryCode="TN" value="Tunisia">Tunisia</option>
              <option data-countryCode="TR" value="Turkey">Turkey</option>
              <option data-countryCode="TM" value="Turkmenistan">
                Turkmenistan
              </option>
              <option data-countryCode="TM" value="Turkmenistan">
                Turkmenistan
              </option>
              <option data-countryCode="TC" value="Turks Caicos Islands">
                Turks &amp; Caicos Islands
              </option>
              <option data-countryCode="TV" value="Tuvalu">Tuvalu</option>
              <option data-countryCode="UG" value="Uganda">Uganda</option>
              <!-- <option data-countryCode="GB" value="44">UK (+44)</option> -->
              <option data-countryCode="UA" value="Ukraine">Ukraine</option>
              <option data-countryCode="AE" value="United Arab Emirates">
                United Arab Emirates
              </option>
              <option data-countryCode="UY" value="Uruguay">Uruguay</option>
              <!-- <option data-countryCode="US" value="1">USA (+1)</option> -->
              <option data-countryCode="UZ" value="Uzbekistan">
                Uzbekistan
              </option>
              <option data-countryCode="VU" value="Vanuatu">Vanuatu</option>
              <option data-countryCode="VA" value="Vatican City">
                Vatican City
              </option>
              <option data-countryCode="VE" value="Venezuela">Venezuela</option>
              <option data-countryCode="VN" value="Vietnam">Vietnam</option>
              <option data-countryCode="VG" value="Virgin Islands - British">
                Virgin Islands - British
              </option>
              <option data-countryCode="VI" value="Virgin Islands - US">
                Virgin Islands - US
              </option>
              <option data-countryCode="WF" value="Wallis Futuna">
                Wallis &amp; Futuna
              </option>
              <option data-countryCode="YE" value="Yemen (North)">
                Yemen (North)
              </option>
              <option data-countryCode="YE" value="Yemen (South)">
                Yemen (South)
              </option>
              <option data-countryCode="ZM" value="Zambia">Zambia</option>
              <option data-countryCode="ZW" value="Zimbabwe">Zimbabwe</option>
            </select></span
          >
        </div>
        <div class="input">
          <label for="city">Operation City</label>
          <span class="drp">
            <select
              class="countrysel"
              name="countryCode"
              id="op_city"
              v-model="operation_city"
            >
              <option
                v-for="city in cities"
                :key="city"
                :value="city"
              >
                {{ city }}
              </option>
            </select>
          </span>
        </div>
        <div class="input">
          <label for="city">Do you do intercity deliveries?</label>
          <span class="drp">
            <select
              class="countrysel"
              name="countryCode"
              id="inter_country"
              v-model="intercity_deliveries"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </span>
        </div>
        <div class="input">
          <label for="firstName">What are your most frequent send rate?</label>
          <input
            type="text"
            name="send_rate"
            id="send_rate"
            v-model="send_rate"
          />
        </div>
        <div class="input">
          <label for="frequented_route">Frequented Route</label>
          <input
            type="text"
            name="frequented_route"
            id="frequented_route"
            v-model="frequented_route"
          />
        </div>
        <div class="input">
          <label for="second_user_state">Second User State</label>
          <span class="drp">
            <select
              class="countrysel"
              name="stateCode"
              id="state"
              v-model="second_user_state"
            >
              <option
                v-for="state in states"
                :key="state.state_code"
                :value="state.name"
              >
                {{ state.name }}
              </option>
            </select>
          </span>
        </div>
        <div class="input">
          <label for="city">Business In Country</label>
          <span class="drp">
            <select
              class="countrysel"
              name="countryCode"
              id="country"
              v-model="business_in_country"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </span>
        </div>
        <div class="input">
          <label for="socialHandle">Social Handle</label>
          <input
            type="text"
            name="socialHandle"
            id="socialHandle"
            v-model="social_handle"
          />
        </div>
        <div class="input">
          <label for="password"> Choose Password</label>
          <input
            type="password"
            name="password"
            id="password"
            v-model="password"
          />
        </div>
        <div class="input">
          <label for="password2"> Confirm Password</label>
          <input
            type="password"
            name="password2"
            id="password2"
            v-model="password2"
          />
        </div>

        <div class="tacbox">
          <input id="checkbox" type="checkbox" />
          <label for="checkbox">
            I agree to these
            <router-link class="conditions" :to="{ name: 'Policy' }"
              >Terms and Conditions</router-link
            >.</label
          >
        </div>

        <button @click.prevent="register" class="register-btn">Sign Up</button>

        <!-- <a class="modal-show" href="#modal">Sign</a> -->

        <div class="modal" id="modal" v-if="showModal">
          <div class="modal-content">
            <a class="modal-hide" @click="showModal = !showModal">✕</a>
            <div class="reg">
              <div class="reg-icon">
                <img
                  :src="require(`../assets/home/success.png`)"
                  alt="success"
                  class="success__icon"
                />
              </div>
              <p class="reg-success">Registration Successful</p>
              <p class="reg-message">
                You can now sign in with your credentials on our Mobile App
              </p>
              <p class="reg-message">Download the Travella App now</p>
              <div class="reg-message">
                <a
                  href="https://play.google.com/store/apps/details?id=org.ibranch.travella"
                  target="_blank"
                  ><img
                    :src="require(`../assets/works/playstore.png`)"
                    alt="PlayStore"
                    class="store__icon"
                /></a>
                <a href="https://apps.apple.com/ng/app/travella/id1567772308"
                  ><img
                    :src="require(`../assets/works/appstore.png`)"
                    alt="App_Store"
                    class="store__icon"
                /></a>
              </div>
              <p class="reg-homelink">
                Or you can return to our
                <router-link class="router-link" :to="{ name: 'Home' }"
                  >Homepage</router-link
                >
              </p>
            </div>
          </div>
        </div>

        <!-- <div class="terms">
          By clicking the “Sign Up” button, you agree to Travella
          <router-link class="conditions" :to="{ name: 'Policy' }">
            terms of acceptable use.</router-link
          >
        </div> -->
        <div v-show="error" class="error">{{ this.errorMsg }}</div>
      </div>
    </form>
  </div>
</template>

<script>
import logo from "../assets/logo.png";
// import password from "../assets/Icons/lock-alt-solid.svg";
// import user from "../assets/Icons/user-alt-light.svg";
// import firebase from "firebase/app";
// import "firebase/auth";
// import db from "../firebase/firebaseInit";
export default {
  name: "Register",
  components: {},
  data() {
    return {
      logo,
      first_name: "",
      last_name: "",
      business_email: "",
      business_address: "",
      password: "",
      password2: "",
      business_number: "",
      business_name: "",
      operation_country: "",
      operation_city: "",
      intercity_deliveries: "",
      send_rate: "",
      frequented_route: "",
      second_user_state: "",
      business_in_country: "",
      social_handle: "",
      country: "+234",
      error: null,
      errorMsg: "",
      showModal: false,
      isRegistered: false,
      states: [],
      cities: [],
    };
  },
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  methods: {
    validate() {
      if (document.getElementById("checkbox").checked) {
        return;
      } else {
        alert(
          "You have to agree with the terms and conditions before you can proceed."
        );
        return false;
      }
    },
    filterState() {
      const rawData = {
        country: this.operation_country,
      };
      this.$http
        .post("https://countriesnow.space/api/v0.1/countries/states", rawData)
        .then((response) => {
          if (response.data) {
            this.states = response.data.data.states;
            console.log(this.states);
          }
        });
    },
    filterCity() {
      const rawData = {
        country: this.operation_country,
        limit: 1000000,
        order: "asc",
        orderBy: "name",
      };
      this.$http
        .post("https://countriesnow.space/api/v0.1/countries/population/cities/filter", rawData)
        .then((response) => {
          if (response.data) {
            this.cities = response.data.data.map((e) => e.city);
            console.log(this.cities);
          }
        });
      this.filterState();
    },
    async register() {
      if (this.password !== this.password2) {
        this.error = true;
        this.errorMsg = "The passwords do not match. Kindly check again!";
        return;
      }
      if (
        this.first_name !== "" &&
        this.last_name !== "" &&
        this.business_email !== "" &&
        this.business_address !== "" &&
        this.business_number !== "" &&
        this.business_name !== "" &&
        this.operation_country !== "" &&
        this.operation_city !== "" &&
        this.intercity_deliveries !== "" &&
        this.send_rate !== "" &&
        this.frequented_route !== "" &&
        this.second_user_state !== "" &&
        this.business_in_country !== "" &&
        this.social_handle !== "" &&
        this.password !== "" &&
        this.password2 !== ""
      ) {
        this.error = false;
        this.errorMsg = "";
        // const baseURI = "https://travellang.herokuapp.com/api/v1/signup";
        // this.$http.get(baseURI).then((result) => {
        //   this.users = result.data;
        // });

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        this.validate();

        var raw = {
          first_name: this.first_name,
          last_name: this.last_name,
          business_email: this.business_email,
          business_address: this.business_address,
          password: this.password,
          business_number: this.country + this.business_number,
          business_name: this.business_name,
          operation_country: this.operation_country,
          operation_city: this.operation_city,
          intercity_deliveries: this.intercity_deliveries,
          send_rate: this.send_rate,
          frequented_route: this.frequented_route,
          second_user_state: this.second_user_state,
          business_in_country: this.business_in_country,
          social_handle: this.social_handle,
        };
        console.log("here", raw);

        this.$http
          .post("https://travella-web.onrender.com/auth/form", raw)
          .then((response) => {
            if (response.data.status) {
              this.showModal = true;
            }
            // this.$router.push({ name: "Home" });
          })
          .then((result) => console.log(result))
          .catch((error) => console.log("error", error));
        return;
      }
      this.error = true;
      this.errorMsg = "Please fill out all the fields!";
      return;
    },
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
}
.register {
  h2 {
    max-width: 350px;
    font-size: 1.4rem;
  }
}
.form-wrap {
  overflow: hidden;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  width: 100%;
  background: var(--first-color);
  @media (min-width: 900px) {
    width: 100%;
  }

  .login-register {
    margin-bottom: 10px;
    max-width: 160px;

    @media (min-width: 900px) {
      margin-bottom: 20px;
      max-width: 200px;
    }

    .router-link {
      color: #000;
    }

    img {
      width: 100%;
    }
  }

  form {
    // padding: 0 10px;
    margin: 20px 10px 50px 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    max-width: 900px;
    @media (min-width: 900px) {
      // padding: 0 50px;
      margin: 20px 50px 75px 20px;
    }

    h2 {
      text-align: center;
      font-size: var(--h3-font-size);
      color: #ffffff;
      margin-bottom: 10px;
      // text-transform: uppercase;
      @media (min-width: 900px) {
        margin-bottom: 20px;
      }
    }

    .inputs {
      width: 100%;
      max-width: 675px;
      background: #ffffff;
      padding: 30px;

      @media (min-width: 900px) {
        padding: 50px 92px;
      }

      .error {
        color: red;
      }

      .conditions {
        color: #ff782e;
      }

      .terms {
        font-size: 15px;
        padding: 5px 7px;
        text-align: center;
      }

      .register-btn {
        width: 100%;
        margin-top: 7px;
        font-size: 20px;
        font-weight: 800;
        height: 50px;
        padding: 0px;
      }

      .input {
        // position: relative;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        margin-bottom: 20px;

        label {
          font-size: 14px;
          display: block;
          padding-bottom: 6px;
        }

        input {
          width: 100%;
          border-radius: 4px;
          border: 1px solid #000000;
          box-sizing: border-box;
          background-color: #ffffff;
          padding: 4px 4px 4px 10px;
          height: 50px;

          &:focus {
            box-shadow: 0 0 28px #ff782e40;
          }
        }

        .icon {
          width: 12px;
          position: absolute;
          left: 6px;
        }
      }
    }

    .forgot-password {
      text-decoration: none;
      color: #000;
      cursor: pointer;
      font-size: 14px;
      margin: 16px 0 32px;
      border-bottom: 1px solid transparent;
      transition: 0.5s ease all;

      &:hover {
        border-color: #303030;
      }
    }

    .angle {
      display: none;
      position: absolute;
      background-color: #fff;
      transform: rotateZ(3deg);
      width: 60px;
      right: -30px;
      height: 101%;
      @media (min-width: 900px) {
        display: initial;
      }
    }
  }
}

.reg {
  width: 100%;
  background: #ffffff;
  padding: 5px;

  @media (min-width: 900px) {
    padding: 10px;
  }

  .reg-success {
    font-size: 25px;
    font-weight: var(--font-semi-bold);
    text-align: center;
    @media (min-width: 900px) {
      font-size: 32px;
    }
  }

  .reg-icon {
    height: 45px;
    margin: var(--h3-font-size);
    text-align: center;
    @media (min-width: 900px) {
      height: 60px;
    }
    .success__icon {
      height: 100%;
      margin: 0.5rem;
    }
  }

  .reg-message {
    font-size: 18px;
    margin: var(--h3-font-size);
    text-align: center;
    @media (min-width: 900px) {
      font-size: 22px;
    }
    .store__icon {
      width: 7.125rem;
      height: 2.5rem;
      margin: 0.5rem;
      border-radius: 0.6rem;
      opacity: 1;
      background-color: white !important;
      border-color: transparent !important;
      image-rendering: -webkit-optimize-contrast;
      @media screen and (min-width: 576px) {
        width: 8.125rem;
      }
    }
  }

  .reg-homelink {
    font-size: 16px;
    font-weight: var(--font-medium);
    text-align: center;
    @media (min-width: 900px) {
      font-size: 20px;
    }

    .router-link {
      color: #ff782e;
      text-decoration: none;
    }
  }
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  animation: modal-show 0.5s 1 forwards;
}

.modal-content {
  position: relative;
  width: 580px;
  min-height: 180px;
  padding: 40px 24px 100px;
  background-color: white;
  border-radius: 8px;
}

// .modal:target {
//   display: flex;
// }

// .modal-show {
//   display: inline-block;
//   padding: 12px;
//   background-color: #8bb02c;
//   color: white;
//   font-size: 18px;
//   font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
//   text-decoration: none;
// }

.modal-hide {
  position: absolute;
  top: 2px;
  right: 6px;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: black;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: var(--primary-color);
  }
}

@keyframes modal-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

select {
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #000000;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 4px 4px 4px 10px;
  height: 50px;

  &:focus {
    box-shadow: 0 0 28px #ff782e40;
  }
}

select:focus {
  box-shadow: 0 0 28px #ff782e40;
}

.drp,
.num {
  display: inline-block;
  background: transparent;
  border-radius: 4px;
  position: relative;
  width: 100%;
}

.drp:after {
  color: var(--primary-color);
  background: transparent;
  position: absolute;
  content: "\25BC";
  top: 0;
  bottom: 0;
  right: 0;
  left: 88%;
  text-align: center;
  line-height: 3em;
  pointer-events: none;
  border-radius: 0 4px 4px 0;
}

.num:after {
  color: var(--primary-color);
  background: transparent;
  position: absolute;
  content: "\25BC";
  top: 0;
  bottom: 0;
  right: 0;
  left: 48%;
  text-align: center;
  line-height: 3em;
  pointer-events: none;
  border-radius: 0 4px 4px 0;
}

.tacbox {
  display: block;
  // padding: 1em;
  margin: 1em 0;
  input#checkbox {
    height: 2em;
    width: 2em;
    vertical-align: middle;
  }
}
</style>
