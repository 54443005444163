<template>
  <div class="bd-container app__box">
    <div class="app__container">
      <div class="app__head">Testing Engineer</div>
      <div class="app__description">
        <div class="app__job">
          <div class="title">Job Description</div>
          <div class="details" style="max-width: 550px">
            Remote, Nigeria, 4 to 5 Years Of Experience Department: Website
            Design 5 Positions Available.
          </div>
        </div>
        <div class="app__salary">
          <div class="title">Salary</div>
          <div class="details">$30000 Per Anum</div>
        </div>
      </div>
      <div class="app__details">
        <div class="details__container">
          <div class="details__title">Details</div>
          <div class="details__subtitle">
            Design & Create highly engaging industry-related content in both
            photo, gif & video format Publish Posts on various social media
            channels
          </div>
        </div>
        <div class="details__container">
          <div class="details__title2">Requirements</div>
          <div class="details__subtitle">
            Promote content on social networks and monitor engagement (e.g.
            comments and shares) Research industry-related topics
          </div>
        </div>
        <div class="details__container">
          <div class="details__title2">Responsibilities</div>
          <div class="details__subtitle">
            Editing audio and sound design on projects Engage in opportunities
            to develop original content and concepts for web and mobile Create
            motion graphics and animations using 2D and 3D applications for
            marketing and promotional usage. Manage the day-to-day handling of
            all social media channels such as LinkedIn, Facebook, Twitter,
            Pinterest, Instagram, Tiktok and YouTube, adapting content to suit
            different channels
          </div>
        </div>
      </div>
    </div>
    <div class="app__container">
      <div class="apply__section">Apply Now</div>
      <div class="apply__box">
        <form class="apply__container">
          <div class="input">
            <input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="First Name"
              v-model="firstName"
            />
          </div>
          <div class="input">
            <input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Last Name"
              v-model="lastName"
            />
          </div>
          <div class="input">
            <input
              type="text"
              name="email"
              placeholder="Email Address"
              id="email"
              v-model="email"
            />
          </div>
          <div class="input">
            <input
              type="text"
              name="phone"
              placeholder="Mobile No"
              id="phone"
              v-model="mobile_no"
            />
          </div>
        </form>
        <form class="apply__container2">
          <textarea
            rows="8"
            type="text"
            name="message"
            placeholder="Why do you think you are good fit for Travella"
            id="message"
            v-model="message"
          />
        </form>
        <div class="submit__button">
          <button @click.prevent="submit" class="apply-btn">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      mobile_no: "",
      message: "",
    };
  },
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  methods: {
    submit() {},
  },
};
</script>

<style lang="scss" scoped>
.app {
  &__box {
    min-height: calc(100vh - 110px);
    padding: 1rem 0;
    @media screen and (min-width: 968px) {
      padding: 4rem 0;
    }
  }
  &__container {
    padding: 2rem 0;
    align-items: start;
  }
  &__head {
    font-weight: var(--font-semi-bold);
    line-height: 54px;
    font-size: var(--large-font-size);
    color: #1d3444;
  }
}
.app__description {
  display: grid;
  gap: 1rem;
  padding: 2rem 0;
  align-items: start;
  grid-template-columns: repeat(1, 1fr);
  @media screen and (min-width: 576px) {
    grid-template-columns: 60% 40%;
  }
  @media screen and (min-width: 968px) {
    grid-template-columns: 80% 20%;
  }
}
.title {
  font-weight: var(--font-semi-bold);
  font-size: 21px;
}
.details {
  line-height: 30px;
}
.app__details {
  background: rgba(255, 102, 0, 0.3);
  border-radius: 20px;
  padding: 1rem;
  @media screen and (min-width: 576px) {
    padding: 1rem 2rem;
  }
  @media screen and (min-width: 968px) {
    padding: 3rem 4rem;
  }
}
.details__container {
  display: grid;
  gap: 2rem;
  padding: 0.5rem 0;
  align-items: start;
  grid-template-columns: repeat(1, 1fr);
  @media screen and (min-width: 576px) {
    grid-template-columns: 140px 1fr;
  }
  @media screen and (min-width: 968px) {
    grid-template-columns: 200px 1fr;
  }
  .details__title {
    font-weight: var(--font-semi-bold);
    font-size: 21px;
    color: var(--primary-color);
    @media screen and (min-width: 576px) {
      font-size: 19px;
    }
    @media screen and (min-width: 968px) {
      font-size: 21px;
    }
  }
  .details__title2 {
    font-weight: var(--font-semi-bold);
    font-size: 21px;
    color: var(--black-color);
    @media screen and (min-width: 576px) {
      font-size: 19px;
    }
    @media screen and (min-width: 968px) {
      font-size: 21px;
    }
  }
  .details__subtitle {
    opacity: 1;
  }
}
.apply__section {
  font-weight: var(--font-semi-bold);
  line-height: 56px;
  font-size: var(--medium-font-size);
  color: #1d3444;
}
.apply__box {
  margin: 1rem 0;
  .apply__container2 {
    display: flex;
    width: 100%;
    margin: 0.75rem 0;
    textarea {
      width: 100%;
      padding: 4px 4px 4px 10px;
      border: none;
      background: rgba(255, 102, 0, 0.17);
      border-radius: 8px;
    }
  }
  .submit__button {
    margin: 1.5rem 0;
  }
  .apply__container {
    display: grid;
    gap: 0.75rem;
    // padding: 8rem 0;
    align-items: start;
    grid-template-columns: repeat(1, 1fr);
    @media screen and (min-width: 968px) {
      grid-template-columns: repeat(2, 1fr);
      // padding: 8rem 0;
    }
    .input {
      margin-bottom: 10px;

      input {
        width: 100%;
        padding: 4px 4px 4px 10px;
        border: none;
        background: rgba(255, 102, 0, 0.17);
        border-radius: 8px;
      }

      input {
        height: 50px;
      }
    }
  }
}
</style>
