<template>
  <div class="app-wrapper">
    <div class="app">
      <Navigation v-if="!navigation" />
      <router-view class="top-margin" />
      <Footer v-if="!navigation" />
    </div>
  </div>
</template>

<script>
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
export default {
  name: "app",
  components: { Navigation, Footer },
  data() {
    return { navigation: null };
  },
  created() {
    this.checkRoute();
    this.$store.dispatch("getPost");
    this.$store.dispatch("getFAQs");
  },
  mounted() {},
  methods: {
    checkRoute() {
      if (this.$route.name === "Register") {
        this.navigation = true;
        return;
      }
      this.navigation = false;
    },
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./assets/styles/variables.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--body-font);
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.link {
  cursor: pointer;
  text-decoration: none;
  font-weight: var(--font-light);
  color: var(--black-color);
}

.link-light {
  color: #fff;
}

.top-margin {
  margin-top: calc(var(--header-height) + 20px);
  @media (min-width: 900px) {
    margin-top: calc(var(--header-height) + 40px + 1rem);
  }
}

.blog-cards {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;
  margin-top: 2rem;
  @media (min-width: 680px) {
    margin-top: 4rem;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1200px) {
    margin-top: 6rem;
    grid-template-columns: repeat(3, 1fr);
  }
}

.slick-prev,
.slick-next {
  background: var(--primary-color) !important;
  border-radius: 15px;
  color: var(--primary-color) !important;
  @media (max-width: 576px) {
    background: var(--primary-color) !important;
    border-radius: 15px;
    color: var(--primary-color) !important;
  }
}
.slick-dots li.slick-active button:before {
  color: var(--primary-color) !important;
}

.lg {
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.md {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    font-size: 1rem;
  }
}

button,
.router-button {
  transition: 500ms ease all;
  cursor: pointer;
  // margin-top: 24px;
  padding: 12px 24px;
  background-color: var(--primary-color);
  color: var(--white-color);
  border-radius: 4px;
  border: none;
  font-weight: var(--font-bold);

  // text-transform: uppercase;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #ff660094;
  }
}

.hover-effect {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;

  &:hover {
    background-color: #ff6600e5;
    box-shadow: 0px 15px 20px #f1e6dee5;
    color: #fff;
    transform: translateY(-7px);
  }
}

.container {
  max-width: 1280px;
  margin: 0 auto;
}

.bd-container {
  max-width: 1280px;
  width: calc(100% - 3rem);
  margin-left: var(--mb-3);
  margin-right: var(--mb-3);
  @media screen and (min-width: 968px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.pp-container {
  max-width: 1000px;
  width: calc(100% - 3rem);
  margin: var(--mb-6) var(--mb-3) 0px;
  padding-top: var(--mb-3);
  @media screen and (min-width: 968px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.sub-bd-container {
  width: 100%;
  margin: 0;
}

.create__container {
  row-gap: 0.5rem;
  margin: 2rem 0;
}

/*========== CLASS CSS ==========*/
.section {
  padding: 4rem 0 2rem;
  @media screen and (min-width: 768px) {
    padding-top: 7rem;
  }
}

// .section__alt {
//   padding: 0.5rem 0 0.5rem;
// }

.section-title {
  font-size: 2rem;
  text-transform: uppercase;
  color: var(--title-color);
  text-align: center;
  //   margin-bottom: var(--mb-3);
  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
}

.section-title-center {
  font-size: var(--h3-font-size);
  color: var(--black-color);
  text-align: center;
}

/*========== LAYOUT ==========*/
.l-main {
  overflow: hidden;
  min-height: calc(100vh - 104px);
}

.l-main-alt {
  overflow: hidden;
  background: #f9f9f9;
}

.bd-grid {
  display: grid;
  gap: 1.5rem;
}

.home-title-center {
  color: var(--black-color);
  font-weight: var(--font-light);
  // font-size: var(--small-font-size);
  @media (max-width: 576px) {
    font-size: 0.9rem;
  }
}

.home__box,
.home-title-center,
.video__box {
  text-align: initial;
}

.home-head {
  color: var(--black-color);
  font-weight: var(--font-bold);
  font-size: var(--biggest-font-size);
  line-height: 65px;
  margin-bottom: 30px;

  @media (max-width: 576px) {
    line-height: 40px;
    margin-bottom: 10px;
  }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    line-height: 50px;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 767px) and (max-width: 990px) {
    line-height: 40px;
    margin-bottom: 25px;
  }
}

.typing-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  animation: blink-caret 0.75s step-end infinite;
}
.row {
  display: block;
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #000;
  }
}

// .home-head .line {
//   background-position: bottom;
//   background-repeat: no-repeat;
//   padding: 13px 1.7rem 13px;
//   margin-left: -1rem;
//   background-size: inherit;
//   background-image: url("./assets/home/line.png");
//   @media screen and (max-width: 768px) {
//     padding: 3px 9px 6px;
//     background-size: contain;
//     margin-left: -0.5rem;
//   }
// }

.socials__icon {
  width: 11rem;
  // height: 3rem;
  margin: 1rem 1rem 1rem 0;
  // padding: 2px 2px 2px 0;
  // border-radius: 0.6rem;
  opacity: 1;
  background-color: white !important;
  border-color: transparent !important;
  image-rendering: -webkit-optimize-contrast;
  @media (max-width: 576px) {
    width: 7rem;
    margin: 0.5rem 0.5rem 0.5rem 0;
  }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    width: 7.6rem;
    margin-top: 17px;
  }
  @media screen and (min-width: 767px) and (max-width: 990px) {
    width: 8.5rem;
    margin-top: 20px;
  }
}

.partners__icon {
  width: 8.7rem;
  // height: 3rem;
  margin: 1rem;
  padding: 2px 2px 2px 0;
  // border-radius: 0.6rem;
  opacity: 1;
  background-color: white !important;
  border-color: transparent !important;
  image-rendering: -webkit-optimize-contrast;
  @media (max-width: 576px) {
    width: 6.6rem;
    margin: 0.5rem 0.5rem 0.5rem 0;
  }
}

.partner__icon {
  margin: 1rem;
  @media (max-width: 968px) {
    margin: 0.7rem;
  }
  @media (max-width: 576px) {
    margin: 0.5rem;
    width: 57px;
  }
}

.section__container {
  row-gap: 0.5rem;
  margin: 0;
  @media screen and (min-width: 968px) {
    // column-gap: 5rem;
  }
  @media screen and (min-width: 768px) {
    padding: 0 2rem;
    margin: 0;
    display: grid;
  }
  @media screen and (min-width: 576px) {
    grid-template-columns: 50% 50%;
    align-items: center;
  }
}

.section__container-box {
  display: grid;
  grid-template-columns: 52% 48%;
  height: 600px;
  align-items: center;
  background-image: url("./assets/landing-page/mock11.png");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    background: none;
    margin: 2rem auto;
  }
}

/*========== FOOTERR ==========*/
.ft__background {
  background-color: var(--primary-color);
  height: 100%;
}

.ft__bg-image {
  background-image: url("./assets/landing-page/cover.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.footerr__title {
  font-size: var(--h3-font-size);
  color: var(--header-color);
  font-weight: var(--font-light);
  margin-bottom: var(--mb-2);
  @media screen and (max-width: 768px) {
    font-size: var(--normal-font-size);
    margin-bottom: var(--mb-1);
  }
}

.footerr__description {
  margin-bottom: var(--mb-3);
  color: var(--header-color);
  font-size: var(--medium-font-size);
  font-weight: var(--font-bold);
  @media screen and (max-width: 768px) {
    font-size: var(--normal-font-size);
    margin-bottom: var(--mb-1);
  }
}

.footerr__description_alt {
  margin-bottom: var(--mb-3);
  color: var(--header-color);
  font-size: var(--biggest-font-size);
  font-weight: var(--font-bold);
  @media screen and (max-width: 768px) {
    font-size: var(--h1-font-size);
    margin-bottom: var(--mb-2);
  }
}

.footerr {
  height: var(--footer-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerr__data {
  max-width: 968px;
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.footerr__container {
  row-gap: 0.5rem;
}

.fstore__icon {
  width: 10.5rem;
  margin: 0.5rem;
  border-radius: 0.7rem;
  opacity: 1;
  background-color: white !important;
  border-color: transparent !important;
  image-rendering: -webkit-optimize-contrast;
  @media screen and (max-width: 768px) {
    width: 7.125rem;
    margin: 0.5rem;
    border-radius: 0.6rem;
  }
  @media (min-width: 900px) {
    width: 13rem;
    margin: 0.5rem;
    border-radius: 0.8rem;
  }
}

.sfstore__icon {
  width: 10.5rem;
  height: 3.2rem;
  margin: 0.5rem;
  border-radius: 0.7rem;
  opacity: 1;
  background-color: white !important;
  border-color: transparent !important;
  image-rendering: -webkit-optimize-contrast;
  @media screen and (max-width: 768px) {
    width: 6rem;
    height: unset;
    margin: 0;
    border-radius: 0.6rem;
  }
  @media (min-width: 900px) {
    width: 13rem;
    height: 4rem;
    margin: 0.5rem;
    border-radius: 0.8rem;
  }
}

.hd-grid {
  text-align: center;
}
</style>
