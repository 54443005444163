<template>
  <section class="" id="feature">
    <div class="feature__container sub-bd-container fmt-grid ft__background">
      <div class="feature__data">
        <div class="feature_title">WHY CHOOSE US</div>
        <p class="feature__description lg">
          Logistics is now <br />Cheaper and Faster! <br />Just connect to<br />
          travelers!
        </p>
        <p class="feature__description md">
          Logistics is now Cheaper and Faster! Just connect to travelers!
        </p>
        <div class="underline"></div>
        <div class="feature-list">
          <div v-for="(feature, index) in features" :key="index">
            <div class="list__item">
              <Check class="list__icon lg" />
              <Checkxs class="list__icon md" />
              <div>
                <span class="list__title"> {{ feature.title }}: </span>
                <span class="list__content">
                  {{ feature.content }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <button class="feature-btn">Learn More</button>
      </div>
      <div class="feature-image">
        <img
          :src="require(`../assets/landing-page/person.png`)"
          alt="Apps"
          class=""
        />
      </div>
    </div>
  </section>
</template>

<script>
import Check from "../assets/landing-page/check.svg";
import Checkxs from "../assets/landing-page/check-xs.svg";
export default {
  name: "features",
  props: ["features"],
  components: {
    Check,
    Checkxs,
  },
};
</script>

<style lang="scss" scoped>
.feature__content {
  justify-content: center;
}

.feature__img {
  width: 280px;
  justify-self: center;
}

.feature-btn {
  border-radius: 4px;
  background: var(--black-color);
  color: var(--text-color-light);
  width: 165px;
  margin-top: 14px;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
    width: 8rem;
    height: 2.6rem;
  }
}

.feature__data {
  color: var(--text-color-light);
}

.feature-list {
  padding-right: 12px;
}

.feature_title {
  font-size: var(--normal-font-size);
  @media screen and (max-width: 768px) {
    font-size: 0.6rem;
  }
}

.feature__description {
  font-size: var(--h1-font-size);
  font-weight: var(--font-medium);
}

.lg {
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.md {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    font-size: 1rem;
  }
}

.feature__link:hover {
  color: var(--first-color);
}

.feature-image {
  text-align: end;
}

.list__title {
  font-weight: var(--font-semi-bold);
}

.list__content {
  font-weight: var(--font-light);
}

.list__item {
  display: grid;
  grid-template-columns: 45px 1fr;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
  @media screen and (max-width: 768px) {
    font-size: 12px;
    grid-template-columns: 20px 1fr;
  }
}

.list__icon {
  margin-right: 20px;
}

.feature__social {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-right: var(--mb-3);
}

.feature__social:hover {
  color: var(--first-color);
}

.underline {
  background: #e7eaee;
  width: 100%;
  height: 1px;
  margin: 1rem 0;
  @media screen and (max-width: 768px) {
    margin: 0.5rem 0;
  }
}

.feature__container {
  margin: 2rem 0;
  padding: 3rem 0 3rem 3rem;
  @media screen and (max-width: 768px) {
    padding: 2rem 0 2rem 2rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.5rem;
  }
}

.fmt-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 3rem;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    grid-template-columns: 100%;
  }
}

.feature {
  height: calc(var(--footer-height) + 1.5rem);
}

.social_icons {
  display: flex;
  flex-direction: column;
}

.feature__copy {
  text-align: center;
  font-size: var(--small-font-size);
  color: var(--text-color-light);
  margin: 4px auto;
}

@media screen and (max-width: 768px) {
  .feature {
    height: calc(var(--footer-height) + 5rem);
    display: inline-block;
  }

  .feature__content {
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 576px) {
  .feature__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
}
</style>
