<template>
  <div>
    <div class="bd-container">
      <div class="faq__head">Get Quick Answers to your question</div>
    </div>
    <div class="bd-container">
      <div class="search__container">
        <div class="email-form">
          <span class="form-control-wrap">
            <input
              class="form-control"
              placeholder="search"
              v-model="searchInput"
              @keyup="filter"
            /> </span
          ><button
            @click="showAll"
            type="submit"
            value="Submit"
            class="form-control submit"
          >
            <img :src="require('../assets/icons/search.png')" />
          </button>
        </div>
      </div>
    </div>
    <div class="bd-container">
      <div class="card-wrapper">
        <div
          class="card-text-wrapper hide"
          id="faq"
          v-for="faq in faqs"
          :key="faq._id"
        >
          <minus class="collapse__icon" id="minus" />
          <plus class="expand__icon" id="plus" />
          <p class="card-title">
            {{ faq.question }}
          </p>
          <p class="card-text" id="text">
            {{ faq.answer }}
          </p>
        </div>
      </div>
    </div>
    <Socials />
    <Semifooter />
  </div>
</template>

<script>
import Semifooter from "../components/Semifooter.vue";
import Socials from "../components/Socials.vue";
import plus from "../assets/icons/plus.svg";
import minus from "../assets/icons/plus-1.svg";
export default {
  name: "FAQ",
  components: {
    Semifooter,
    Socials,
    plus,
    minus,
  },
  data() {
    return {
      searchInput: "",
      isVisible: false,
    };
  },
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  computed: {
    faqs() {
      return this.$store.state.FAQs;
    },
  },
  mounted() {
    let answers = document.querySelectorAll(".card-text-wrapper");
    answers.forEach((event) => {
      event.addEventListener("click", () => {
        if (event.classList.contains("show")) {
          event.classList.remove("show");
          event.classList.add("hide");
        } else {
          event.classList.remove("hide");
          event.classList.add("show");
        }
      });
    });
  },
  methods: {
    filter() {
      if (!this.searchInput) {
        return;
      }
      // var filter, faq, p;
      var filter, faq, p, txtValue, txtValue2;
      filter = this.searchInput.toUpperCase();
      faq = document.querySelectorAll(".card-text-wrapper");
      faq.forEach((question) => {
        p = question.getElementsByTagName("p");
        const p1 = p[0];
        const p2 = p[1];
        txtValue = p1.innerText;
        txtValue2 = p2.innerText;

        if (
          txtValue.toUpperCase().indexOf(filter) > -1 ||
          txtValue2.toUpperCase().indexOf(filter) > -1
        ) {
          question.style.display = "";
        } else {
          question.style.display = "none";
        }
      });
    },
    showAll() {
      this.searchInput = "";
      document.querySelectorAll(".card-text-wrapper").forEach((question) => {
        question.style.display = "block";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.email-form {
  width: 100%;
  display: inline-block;
  border: 1px solid #b3b3b3;
  border-radius: 35px;
  position: relative;
  line-height: 0;
  .form-control {
    display: inline-block;
    border: 0;
    outline: 0;
    font-size: 1rem;
    color: rgb(97, 95, 95);
    background-color: transparent;
    font-family: inherit;
    margin: 0;
    padding: 0 3rem 0 1.5rem;
    width: 100%;
    height: 70px;
    border-radius: 35px;
    border: 1px solid #ff6600;
    transition: all 250ms ease-in-out;
    @media (max-width: 576px) {
      height: 50px;
    }
    @media (max-width: 768px) {
      height: 60px;
    }
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }

    &:hover,
    &:focus {
      border: 1px solid #ff6600;
    }
  }
  .submit {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 15px;
    width: 66px;
    // height: 70px;
    font-size: 1rem;
    text-align: center;
    margin: auto 0;
    padding: 0;
    outline: 0;
    border: 0;
    cursor: pointer;
    &:hover,
    &:focus {
      border: none;
    }
  }
}
.faq__head {
  font-size: 36px;
  margin: 60px 10px 20px;
  padding: 5px;
  text-align: center;
  @media screen and (max-width: 768px) {
    margin: 52px auto 15px;
    font-size: 32px;
  }
  @media (max-width: 576px) {
    margin: 45px auto 10px;
    font-size: 28px;
  }
}
.search__container {
  max-width: 700px;
  margin: 5px auto;
}
.search {
  &__input {
    width: 100%;
    padding: 1.5rem 24px;
    padding-left: 3rem;
    background-color: transparent;
    transition: transform 250ms ease-in-out;
    font-size: 14px;
    line-height: 18px;
    color: #575756;
    background-color: transparent;
    background-image: url("../assets/icons/search.png");
    background-repeat: no-repeat;
    background-size: 27px 27px;
    background-position: 10% center;
    border-radius: 50px;
    border: 1px solid #ff6600;
    transition: all 250ms ease-in-out;
    backface-visibility: hidden;
    transform-style: preserve-3d;

    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
      text-transform: lowercase;
      padding-left: 7rem;
      //   letter-spacing: 1.5px;
    }

    &:hover,
    &:focus {
      border: 1px solid #ff6600;
    }
  }
}
.card-wrapper {
  width: 100%;
  margin-top: 6rem;
  position: relative;
  @media screen and (max-width: 768px) {
    margin-top: 4.5rem;
  }
}
.card-text {
  font-weight: var(--font-weight-medium);
  line-height: 27px;
  width: 80%;
  margin-top: 1rem;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.card-text-wrapper {
  bottom: 0;
  width: 100%;
  margin: 2rem 0;
  padding: 3rem;
  box-sizing: border-box;
  // background-color: blue;

  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 4px 10px rgba(8, 96, 208, 0.12);
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
  &.hide .collapse__icon {
    display: none;
  }
  &.hide .expand__icon {
    display: block;
  }
  &.hide .card-text {
    display: none;
  }
  &.show .collapse__icon {
    display: block;
  }
  &.show .expand__icon {
    display: none;
  }
  &.show .card-text {
    display: block;
  }
}
.card-title {
  font-size: 21px;
  line-height: 30px;
  font-weight: var(--font-medium);
}
.expand__icon,
.collapse__icon {
  float: right;
  text-align: center;
}
</style>
