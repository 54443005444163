<template>
  <div>
    <main class="l-main">
      <section class="home bd-container" id="home">
        <!-- <div class="home-wrapper"></div> -->
        <div class="section__container-box">
          <div class="home__box">
            <div class="home-head">
              <vue-typer
                :text="[
                  'Now you can make money traveling',
                  'Pick up packages, deliver and earn',
                  'Send packages faster and more affordably',
                ]"
                :repeat="Infinity"
                :shuffle="false"
                initial-action="typing"
                :pre-type-delay="70"
                :type-delay="70"
                :pre-erase-delay="4000"
                :erase-delay="2000"
                erase-style="clear"
                :erase-on-complete="true"
                caret-animation="expand"
              ></vue-typer>
            </div>
            <h3 class="home-title-center">
              Travella connects individual travelers with<br />
              packages enroute their destination
            </h3>
            <div class="video">
              <a href="https://apps.apple.com/ng/app/travella/id1567772308"
                ><img
                  :src="require(`../assets/works/appstore.png`)"
                  alt="App_Store"
                  class="socials__icon"
              /></a>
              <a
                href="https://play.google.com/store/apps/details?id=org.ibranch.travella"
                target="_blank"
                ><img
                  :src="require(`../assets/works/playstore.png`)"
                  alt="PlayStore"
                  class="socials__icon"
              /></a>
            </div>
          </div>
          <div class="home__img">
            <img :src="require(`../assets/landing-page/mock11.png`)" alt="" />
            <!-- <div class="biz mobile">
              <h3 class="biz-count">
                Over 1000+ trusted online sellers and Merchants
              </h3>
              <div class="partners">
                <img
                  :src="require(`../assets/landing-page/partner1.png`)"
                  alt="PlayStore"
                  class="partners__icon"
                />
                <img
                  :src="require(`../assets/landing-page/partner2.png`)"
                  alt="PlayStore"
                  class="partners__icon"
                />
              </div>
            </div> -->
          </div>
        </div>
      </section>
    </main>

    <main class="l-main-alt">
      <!-- <section class="" id="footer" style="margin-top: 100px">
        <div class="footer__container sub-bd-container hd-grid ft__background">
          <div class="color-overlay">
            <div class="delivery__data">
              <p class="delivery-head">Be like Smart John</p>
              <h3 class="delivery-title-center">
                Make extra cash while traveling by delivering domestic packages
                for individuals on Travella
              </h3>
              <div class="get-started">
                <router-link class="link" :to="{ name: 'Register' }"
                  ><button class="start">For Businesses</button></router-link
                >
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="" id="footer" style="margin-bottom: 220px">
        <div class="footer__container sub-bd-container hd-grid fd__background">
          <div class="color-overlay">
            <div class="logistics__data">
              <p class="logistics-head">For Small and Medium Sized companies</p>
              <h3 class="logistics-title-center">
                Adekemi now uses Travella to deliver her goods across the nation
                and reduces logistic cost. You should be like her
              </h3>
              <div class="get-going">
                <router-link class="link" :to="{ name: 'Register' }"
                  ><button class="start">For Businesses</button></router-link
                >
              </div>
            </div>
          </div>
        </div>
      </section> -->

      <section class="containerr slider-section-invisible" id="home">
        <div class="blog-cards home-wrapper bd-container">
          <DoCard :post="post" v-for="(post, index) in doCards" :key="index" />
        </div>
      </section>
      <section class="slider-section-visible">
        <FeatureSlide :doCards="doCards" />
      </section>

      <Semifooter class="download__section" />
      <section class="home bd-container download__section" id="home">
        <h2 class="trusted-title">Trusted partners</h2>
        <div class="trust">
          <div class="trusted">
            <!-- <div class="partners"> -->
            <img
              :src="require(`../assets/landing-page/partner1.png`)"
              alt="partner"
              class="partner__icon"
            />
            <img
              :src="require(`../assets/landing-page/partner2.png`)"
              alt="partner"
              class="partner__icon"
            />
            <img
              :src="require(`../assets/landing-page/partner3.png`)"
              alt="partner"
              class="partner__icon"
            />
            <img
              :src="require(`../assets/landing-page/partner4.png`)"
              alt="partner"
              class="partner__icon"
            />
            <img
              :src="require(`../assets/landing-page/partner5.png`)"
              alt="partner"
              class="partner__icon"
            />
            <img
              :src="require(`../assets/landing-page/partner6.png`)"
              alt="partner"
              class="partner__icon"
            />
            <!-- </div> -->
          </div>
        </div>
        <div class="get-partner">
          <router-link class="link" :to="{ name: 'Home' }"
            ><button class="start partner-btn hover-effect">
              Become a partner
            </button></router-link
          >
        </div>
      </section>
      <section
        class="home bd-container download__section"
        id="home"
        style="margin-bottom: 20px"
      >
        <h2 class="users__testimonial desktop">
          <span style="color: var(--primary-color)"> What Users Say</span>
        </h2>
        <h2 class="users__testimonial mobile">
          What <span style="color: var(--primary-color)"> Users </span> Say
        </h2>
        <div class="trust">
          <img
            :src="require(`../assets/home/user.png`)"
            alt="User"
            class="trustee"
          />
        </div>
      </section>
      <section style="max-width: 90%; margin: auto">
        <testimonial class="test" />
      </section>
      <Newsletter />
      <Features :features="features" />
    </main>
  </div>
</template>

<script>
import Semifooter from "../components/Semifooter.vue";
import DoCard from "../components/DoCard.vue";
import Testimonial from "../components/Testimonial.vue";
import Features from "../components/Features.vue";
import Newsletter from "../components/Newsletter.vue";
import FeatureSlide from "../components/FeatureSlide.vue";
export default {
  name: "Home",
  components: {
    Semifooter,
    DoCard,
    Testimonial,
    Features,
    Newsletter,
    FeatureSlide,
  },
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  data() {
    return {
      doCards: [
        {
          title: "User Verification",
          subtitle: "Trustworthiness",
          content:
            "Our first layer ensures security by implementing robust user verification processes. By confirming the identity of each user, we create a trustworthy foundation for our platform.",
          image: "fast",
        },
        {
          title: "User Profile",
          subtitle: "Transparency",
          content:
            "The second layer focuses on user profiles, enabling individuals to maintain accurate and up-to-date information. This contributes to a secure environment where transparency is key.",
          image: "accurate",
        },
        {
          title: "Insurance",
          subtitle: "Insurance",
          content:
            "Our third layer enhances security by offering comprehensive insurance coverage. This added protection not only safeguards users but also fosters a sense of confidence and peace of mind within our community.",
          image: "reliable",
        },
      ],
      features: [
        {
          title: "Cost Efficiency",
          content:
            "Travella makes logistics more affordable by utilizing travelers' existing routes for package delivery.",
        },
        {
          title: "Speed and Reliability",
          content:
            "With Travella, your packages are delivered swiftly and reliably by our network of dedicated travelers.",
        },
        {
          title: "Sustainability",
          content:
            "Choose Travella for eco-friendly logistics, as we reduce carbon emissions by leveraging travelers' journeys.",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.home-wrapper {
  // display: flex;
  flex-direction: column;
  @media (min-width: 700px) {
    // min-height: 650px;
    // max-height: 650px;
    flex-direction: row;
  }
}

.mobile {
  display: none;
  text-align: center;
  @media (max-width: 700px) {
    display: block;
  }
  .partners {
    justify-content: center;
  }
}
.desktop {
  display: block;
  @media (max-width: 700px) {
    display: none;
  }
}

.video {
  grid-template-columns: repeat(2, 200px);
  margin-top: 30px;
  align-items: center;
  gap: 0px;
  width: 100%;
  display: grid;
  @media (max-width: 576px) {
    gap: 0px;
    grid-template-columns: repeat(2, 125px);
    margin-top: 15px;
  }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    gap: 25px;
    margin-top: 17px;
    grid-template-columns: repeat(2, 150px);
  }
  @media screen and (min-width: 767px) and (max-width: 990px) {
    gap: 30px;
    grid-template-columns: repeat(2, 165px);
    margin-top: 20px;
  }
  .video__box {
    // display: inline-block;
    font-size: var(--normal-font-size);
    font-weight: var(--font-weight-light);
    @media (max-width: 576px) {
      font-size: 0.838rem;
    }
  }
  .video__icon {
    // display: inline-block;
  }
}

// .video {
//   grid-template-columns: repeat(2, 1fr);
//   align-items: center;
//   width: 100%;
//   height: 100%;
//   .video__box {
//     width: 100px;
//     height: 100%;
//     display: inline-block;
//     font-size: var(--normal-font-size);
//     font-weight: var(--font-weight-light);
//   }
// }

.download__section {
  padding: 85px 0 0;
  background: #f9f9f9;
  @media (min-width: 576px) {
    padding: 85px 0 0;
  }
  @media (min-width: 768px) {
    padding: 100px 0 0;
  }
  @media (min-width: 968px) {
    padding: 120px 0 0;
  }
}

.get-working {
  text-align: center;
  margin: 170px auto;
  @media (max-width: 576px) {
    margin: 170px auto 120px auto;
  }
  button.start {
    width: 17rem;
    height: 4rem;
    font-size: 1.2rem;
    @media screen and (max-width: 576px) {
      font-size: 0.8rem;
      width: 11rem;
      height: 2.6rem;
    }
  }
}

.get-partner {
  text-align: center;
  margin: 50px auto;
  @media (max-width: 576px) {
    margin: 10px auto;
  }
  button.start {
    width: 17rem;
    height: 4rem;
    font-size: 1.2rem;
    @media screen and (max-width: 576px) {
      font-size: 0.8rem;
      width: 11rem;
      height: 2.6rem;
    }
  }
}

.get-going {
  text-align: start;
  margin: 50px auto;
  @media (max-width: 576px) {
    margin: 10px auto;
  }
  button.start {
    width: 17rem;
    height: 4rem;
    font-size: 1.2rem;
    @media screen and (max-width: 576px) {
      font-size: 0.8rem;
      width: 11rem;
      height: 2.6rem;
    }
    @media (max-width: 576px) {
      width: 13rem;
      height: 3.5rem;
    }
  }
}
.get-started {
  text-align: end;
  margin: 50px auto;
  @media (max-width: 576px) {
    margin: 10px auto;
  }
  button.start2 {
    width: 17rem;
    height: 4rem;
    font-size: 1.2rem;
    @media screen and (max-width: 576px) {
      font-size: 0.8rem;
      width: 11rem;
      height: 2.6rem;
    }
    @media (max-width: 576px) {
      width: 13rem;
      height: 3.5rem;
    }
  }
  button.start {
    background-color: var(--white-color);
    color: var(--primary-color);
    width: 17rem;
    height: 4rem;
    font-size: 1.2rem;
    @media screen and (max-width: 576px) {
      font-size: 0.8rem;
      width: 11rem;
      height: 2.6rem;
    }
    @media (max-width: 576px) {
      width: 13rem;
      height: 3.5rem;
    }

    &:hover {
      background-color: var(--second-color);
    }
  }
}

.ease-container {
  bottom: 0;
  // position: absolute;
}

.trusted-title {
  font-size: 1.6rem;
  color: var(--black-color);
  text-align: center;
  font-weight: var(--font-medium);
  //   margin-bottom: var(--mb-3);
  letter-spacing: 0.185em;
  @media (max-width: 576px) {
    letter-spacing: 0.385em;
    font-size: 1.2rem;
  }
  @media screen and (min-width: 576px) and (max-width: 768px) {
    letter-spacing: 0.385em;
    font-size: 1.6rem;
  }
  @media screen and (min-width: 767px) and (max-width: 990px) {
    letter-spacing: 0.685em;
    font-size: 2rem;
  }
}

.test {
  margin-top: var(--mb-3) auto;
}

.users__testimonial {
  //   margin-bottom: var(--mb-3);
  // margin: 70px;
  // padding: 5px 100px;

  text-align: center;
  font-weight: var(--font-bold);
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  .desktop {
    color: var(--primary-color);
    display: flex;
    @media (max-width: 700px) {
      display: none;
    }
  }
  .mobile {
    color: var(--black-color);
    display: none;
    text-align: center;
    @media (max-width: 700px) {
      display: flex;
    }
  }
}
.bg-primary {
  position: relative;
  background: linear-gradient(180deg, #ff6600 0%, #dd5800 100%);
  border-radius: 0px 150px 0px 300px;
  background-position: -10%;
  // height: 550px;
}
.section-card {
  min-height: 150px;
  background: #ffffff;
  border-radius: 30px;
  color: var(--primary-color);
  text-align: center;
  font-weight: var(--font-bold);
  font-size: 1.7rem;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1050px;
  // margin: 40px auto;
  // margin-bottom: -160px;
  // height: 200px;
  // z-index: 1;
  @media (max-width: 768px) {
    max-width: 90%;
  }
  @media (max-width: 576px) {
    border-radius: 8px;
    // box-shadow: 3px 3px 7px 7px rgba(0, 0, 0, 0.25);
    margin: 40px 5px;
    padding: 15px 13px;
    max-width: 90%;
    // margin-bottom: 40px 5px -160px 40px;
    // z-index: 1;
  }
  @media (min-width: 576px) {
    margin: 50px;
    padding: 25px 55px;
    // padding: 25px 150px;
    // margin: 50px 50px -160px 50px;
    // z-index: 1;
  }
  @media (min-width: 768px) {
    margin: 60px;
    font-size: 1.8rem;
    padding: 40px 80px;

    // margin: 60px 60px -160px 60px;
    // font-size: 1.8rem;
    // padding: 40px 200px;
    // // margin-bottom: -160px;
    // z-index: 1;
  }
  @media (min-width: 968px) {
    margin: 70px;
    font-size: 2.5rem;
    padding: 50px 100px;

    // margin-bottom: -160px;
    // z-index: 1;
    // margin: 70px 70px -160px 70px;
    // font-size: 2.5rem;
    // padding: 50px 270px;
  }
}

// .delivery__container {
//   min-height: 983px;
//   @media (max-width: 576px) {
//     min-height: 750px;
//   }
//   // max-height: 983px;

//   .delivery {
//     background-image: url("../assets/landing-page/firstImage.png");
//     background-position: top center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     height: 80%;
//     @media (max-width: 576px) {
//       background-position: 40%;
//       height: 60%;
//     }
//     @media (max-width: 768px) {
//       background-position: 40%;
//       height: 70%;
//     }
//     .delivery__data {
//       text-align: end;
//       // height: 100%;
//       padding-left: 20%;
//       @media (min-width: 576px) {
//         padding-left: 20%;
//       }
//       @media (min-width: 768px) {
//         padding-left: 40%;
//       }
//       // max-width: 50%;
//     }
//     .delivery-head {
//       color: var(--white-color);
//       font-weight: var(--font-bold);
//       font-size: var(--medium-font-size);
//     }
//     .delivery-title-center {
//       font-size: 1.2rem;
//       color: var(--white-color);
//     }
//   }
// .logistics {
//   background-image: url("../assets/landing-page/secondImage.png");
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   height: 80%;
//   @media (max-width: 576px) {
//     background-position: 88%;
//     height: 60%;
//   }
//   @media (max-width: 768px) {
//     background-position: 88%;
//     height: 70%;
//   }
// }

.logistics__data {
  text-align: start;
  width: 60%;
  margin-right: 40%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  @media (max-width: 768px) {
    width: 85%;
    margin-right: 15%;
  }
  @media (max-width: 968px) {
    width: 75%;
    margin-right: 25%;
  }
}
.delivery__data {
  text-align: end;
  width: 60%;
  margin-left: 40%;
  margin-right: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  @media (max-width: 768px) {
    width: 85%;
    margin-left: 15%;
  }
  @media (max-width: 968px) {
    width: 75%;
    margin-left: 25%;
  }
}
.logistics-head {
  color: var(--black-color);
  font-weight: var(--font-bold);
  font-size: var(--large-font-size);
}
.logistics-title-center {
  font-size: var(--h2-font-size);
  color: var(--black-color);
  margin: 30px auto;
  font-weight: var(--font-bold);
  @media (max-width: 576px) {
    margin: 10px auto;
  }
}
.delivery-head {
  color: var(--white-color);
  font-weight: var(--font-bold);
  font-size: var(--large-font-size);
}
.delivery-title-center {
  font-size: var(--h2-font-size);
  color: var(--white-color);
  margin: 30px auto;
  font-weight: var(--font-bold);
  @media (max-width: 576px) {
    margin: 10px auto;
  }
}
.home__img {
  justify-self: center;
  padding: 0;
  width: 100%;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
  // img {
  //   // max-height: 800px;
  //   width: 100%;
  // }
}
.trust {
  margin-top: var(--mb-3);
  @media (max-width: 576px) {
    margin-top: var(--mb-1);
  }
}
.biz {
  margin-top: var(--mb-6);
  @media (max-width: 576px) {
    margin-top: var(--mb-3);
  }
}
.trusted {
  // display: flex;
  // align-items: center;
  text-align: center;
  gap: 20px;
  display: grid;
  grid-template-rows: 50px;
  grid-template-columns: repeat(6, 120px);
  align-items: center;
  justify-content: center;
  margin: 1.5rem auto;
  @media (max-width: 968px) {
    display: grid;
    gap: 20px;
    grid-template-rows: 50px;
    grid-template-columns: repeat(6, 90px);
  }
  @media (max-width: 576px) {
    display: grid;
    gap: 20px;
    grid-template-rows: 26px;
    grid-template-columns: repeat(3, 57px);
  }
  .svg-icon {
    // width: 8.125rem;
    // height: 2.5rem;
    margin: 1rem 1.5rem 1rem 0;
    color: var(--black-color);
  }
}
.trustee {
  display: flex;
  align-items: center;
  @media (max-width: 576px) {
    height: 100px;
  }
}
.partners {
  display: flex;
  align-items: center;
  .svg-icon {
    // width: 8.125rem;
    // height: 2.5rem;
    margin: 1rem 1rem 1rem 0;
    color: var(--black-color);
  }
}

.sub-bd-container {
  /* max-width: 968px; */
  width: 100%;
  margin: 0;
}

.hd-grid {
  text-align: end;
}
.ft__background {
  background-image: url("../assets/landing-page/firstImage.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  height: 100%;
}
.fd__background {
  background-image: url("../assets/landing-page/secondImage.png");
  background-position: 60% 40%;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  height: 100%;
  @media (max-width: 576px) {
    background-position: 90% 10%;
  }
}
.footer__container {
  row-gap: 0.5rem;
  // padding: 8rem 0;
  @media screen and (min-width: 968px) {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    // padding: 16rem 8rem;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
}
.footer__container .color-overlay {
  padding: 8rem 0;
  @media screen and (min-width: 968px) {
    padding: 16rem 8rem;
  }
  @media screen and (max-width: 768px) {
    padding: 8rem 0;
  }
}

.containerr {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  @media (max-width: 680px) {
    height: 920px;
  }
  @media (min-width: 680px) and (max-width: 968px) {
    height: 332px;
  }
  // margin: 0;
}

.slider-section-invisible {
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.slider-section-visible {
  display: none;
  .blog-card {
  }
  @media screen and (max-width: 768px) {
    display: block;
    max-width: 90%;
    margin: 2rem auto;
  }
}
.box {
  position: absolute;
  top: -155px;
  left: 0;
  right: 0;
  opacity: 1;
  box-shadow: 3.72794px 3.72794px 7.45587px rgba(0, 0, 0, 0.25);
  @media (max-width: 680px) {
    top: -112px;
  }
  @media (min-width: 680px) and (max-width: 968px) {
    top: -105px;
  }
}
.overlay {
  margin: 30px auto;
  @media (max-width: 576px) {
    margin: 30px 20px;
  }
  @media (max-width: 768px) {
    margin: 30px 30px;
  }
  @media (max-width: 1024px) {
    margin: 30px 40px;
  }
}
.card-box {
  position: absolute;
  bottom: -110px;
  left: 0;
  right: 0;
  opacity: 1;
  @media (max-width: 680px) {
    bottom: -152px;
  }
}
// .overlay {
//   margin: 30px auto;
// }
</style>
