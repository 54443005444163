<template>
  <div class="bd-container news">
    <div class="news-section">
      <div class="instructions">
        <div class="news-title">Newsletter</div>
        <div class="news-subtitle">
          Be the first one to know about discounts, offers and events
        </div>
      </div>
      <div class="email-input-wrapper">
        <envelop class="prefix-button" />
        <input
          type="email"
          class="email-input"
          placeholder="Enter your email"
        />
        <button class="suffix-button">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import envelop from "../assets/icons/envelop.svg";
export default {
  name: "newsletter",
  components: { envelop },
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.news {
  margin: 10rem auto;
  @media screen and (max-width: 768px) {
    margin: 8rem 0 5rem;
    width: 100%;
  }
}
.news-section {
  display: grid;
  grid-template-columns: 60% 40%;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  background: #ff782e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1.5rem 2rem;
  @media screen and (max-width: 768px) {
    border-radius: unset;
    box-shadow: unset;
    grid-template-columns: 100%;
    gap: 8px;
  }
}

.instructions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.news-title,
.news-subtitle {
  color: var(--white-color);
  font-size: var(--normal-font-size);
}

.news-title {
  font-weight: 500;
}

.news-subtitle {
  font-weight: 400;
  @media screen and (max-width: 768px) {
    font-size: 0.65rem;
  }
}

.email-input-wrapper {
  position: relative;
  display: inline-block;
  border-radius: 12px;
  background: #000;
  padding: 4px;
}

.email-input {
  border: none;
  border-radius: 4px;
  padding: 10px 40px 10px 35px;
  background: transparent;
  font-size: var(--normal-font-size);
  font-weight: 400;
  color: var(--white-color);
}

.prefix-button {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.suffix-button {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  background-color: #007bff;
  color: #ff782e;
  border: none;
  font-weight: 500;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 8px;
  background: var(--white-color);
}
</style>