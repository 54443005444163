<template>
  <section class="" id="footerr">
    <div
      class="footerr__container sub-bd-container hd-grid ft__background bd_rd ft__bg-image"
    >
      <div class="footerr__data">
        <p class="footerr__description_alt">Download the Travella App now</p>
        <p class="footerr__title">Available on Playstore and App store</p>
        <a
          href="https://play.google.com/store/apps/details?id=org.ibranch.travella"
          target="_blank"
          ><img
            :src="require(`../assets/works/playstore.png`)"
            alt="PlayStore"
            class="fstore__icon"
        /></a>
        <a href="https://apps.apple.com/ng/app/travella/id1567772308"
          ><img
            :src="require(`../assets/works/appstore.png`)"
            alt="App_Store"
            class="fstore__icon"
        /></a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "semifooteralt",
};
</script>

<style lang="scss" scoped>
.bd_rd {
  border-radius: 60px;
  @media screen and (max-width: 768px) {
    border-radius: 25px;
  }
}

.footerr__content {
  justify-content: center;
}

.footerr__img {
  width: 280px;
  justify-self: center;
}

.footerr__link:hover {
  color: var(--first-color);
}

.footerr__social {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-right: var(--mb-3);
}

.footerr__social:hover {
  color: var(--first-color);
}

.footerr__container {
  padding: 3rem;
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.5rem;
  }
}

.footerr {
  height: calc(var(--footer-height) + 1.5rem);
}

.footerr__copy {
  text-align: center;
  font-size: var(--small-font-size);
  color: var(--text-color-light);
  margin: 4px auto;
}

@media screen and (max-width: 768px) {
  .footerr {
    height: calc(var(--footer-height) + 5rem);
    display: inline-block;
  }

  .footerr__content {
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 576px) {
  .footerr__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
}
</style>
