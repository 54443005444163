<template>
  <div class="bd-container hire__box">
    <div class="hire__container">
      <div class="hire__left">
        <div class="hire__head">
          We are a group of young and motivated individuals working to shape the
          future of people-centered logistics
        </div>
        <div class="contact__subhead">
          Working with us allows you to do what you love while also contributing
          to a community that is building the future.
        </div>
        <router-link class="link" :to="{ name: 'Application' }">
          <button class="contact-btn">
            View Available Positions
          </button></router-link
        >
      </div>
      <div class="hire__right">
        <img
          :src="require(`../assets/hire/hire.png`)"
          alt="Hire"
          class="hire__icon"
        />
      </div>
    </div>
    <div class="position__container">
      <div class="position__head">See our open positions</div>
      <div class="position__boxes">
        <div
          class="position__box"
          v-for="position in positions"
          :key="position.id"
        >
          <div class="position__title">
            {{ position.name }}
          </div>
          <div class="position__subtitle">
            {{ position.location }}
          </div>
          <div class="position-button">
            <router-link class="link position-btn" :to="{ name: 'Application' }">
              Apply Now</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  data() {
    return {
      positions: [
        {
          name: "API Engineer, NodeJs",
          location: "Remote | Full Time ( Any Country )",
          id: "1",
        },
        {
          name: "Frontend Engineer",
          location: "Remote | Full Time ( Any Country )",
          id: "2",
        },
        {
          name: "Product Design",
          location: "Remote | Full Time ( Any Country )",
          id: "3",
        },
        {
          name: "SEO Manager",
          location: "Remote | Full Time ( Any Country )",
          id: "4",
        },
        {
          name: "Content Creator",
          location: "Remote | Full Time ( Any Country )",
          id: "5",
        },
        {
          name: "Marketer",
          location: "Remote | Full Time ( Any Country )",
          id: "6",
        },
      ],
    };
  },
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
};
</script>
 
<style lang="scss" scoped>
.hire__box {
  min-height: calc(100vh - 110px);
  padding: 1rem 0;
  @media screen and (min-width: 968px) {
    padding: 4rem 0;
  }

  .hire__container {
    display: grid;
    gap: 4rem;
    padding: 2rem 0;
    align-items: start;
    grid-template-columns: repeat(1, 1fr);
    @media screen and (min-width: 968px) {
      grid-template-columns: repeat(2, 1fr);
      // padding: 8rem 0;
    }
    .hire__left,
    .hire__right {
      max-width: 400px;
      @media screen and (min-width: 968px) {
        max-width: 510px;
      }
    }
    .hire__head {
      font-weight: var(--font-semi-bold);
      line-height: 54px;
      font-size: var(--large-font-size);
      color: #1d3444;
    }
    .contact__subhead {
      margin: 1.5rem 0;
    }
    .hire__icon {
      justify-self: center;
      width: 100%;
      margin: auto;
      // @media (max-width: 576px) {
      //   margin: 4rem auto;
      // }
    }
  }
  .position__container {
    padding: 2rem 0;
    .position__head {
      font-weight: var(--font-semi-bold);
      font-size: var(--medium-font-size);
      line-height: 56px;
      margin: 2rem 0;
      text-align: start;
    }
    .position__boxes {
      display: grid;
      gap: 2.5rem;
      max-width: 1300px;
      // margin: 1rem auto;
      align-items: start;
      grid-template-columns: repeat(1, 1fr);
      @media screen and (min-width: 968px) {
        grid-template-columns: repeat(3, 1fr);
        // padding: 8rem 0;
      }
      .position {
        &__box {
          background: rgba(255, 102, 0, 0.3);
          border-radius: 20px;
          padding: 2rem;
        }
        &__title {
          font-size: 21px;
          font-weight: var(--font-medium);
        }
        &__subtitle {
          font-weight: var(--font-medium);
          line-height: 30px;
          opacity: 0.6;
        }
        &-button {
          margin-top: 1rem;
        }
        &-btn {
          color: var(--primary-color);
        }
      }
    }
  }
}
</style>
 

