<template>
  <div>
    <main class="l-main">
      <!--========== HOME ==========-->
      <section class="home" id="home">
        <div class="home__container bd-container hd-grid bd__background">
          <div class="home__data">
            <!-- <p class="home__title">Food app</p> -->
            <p class="home__description">
              Logistics <br class="lg" />Redefined
            </p>
            <!-- HERE -->
            <p class="home__subdescription">
              You get to do business efficiently with affordable logistics.
            </p>
            <button class="travella primary-color">Join Travella Tribe</button>
            <!-- <p class="home__title">Download the Travella App now</p> -->
            <!-- <a
                href="https://play.google.com/store/apps/details?id=org.ibranch.travella"
                target="_blank"
                ><img
                  :src="require(`../assets/works/playstore.png`)"
                  alt="PlayStore"
                  class="store__icon"
              /></a>
              <a
                href="https://apps.apple.com/ng/app/travella/id1567772308"
                target="_blank"
                ><img
                  :src="require(`../assets/works/appstore.png`)"
                  alt="App_Store"
                  class="store__icon"
              /></a> -->
          </div>
        </div>
      </section>

      <!--========== APP MOCKUP ==========-->
      <!-- <section class="share section__alt bd-container">
        <div class="share__container">
          <div class="share__img">
            <img
              class="center"
              :src="require(`../assets/works/mockup1.png`)"
              alt=""
            />
          </div>
        </div>
      </section> -->

      <!--========== WORKS ==========-->
      <section class="download section__alt bd-container" id="download">
        <div class="bd-grid">
          <h2 class="section-title primary-color">How Travella Works</h2>
        </div>
        <div class="feature-grid">
          <div v-if="tab !== 1" class="feature pointer" @click="tab = 1">Traveler</div>
          <div v-else class="feature active">
            <img
              :src="require(`../assets/works/active.png`)"
              alt=""
              style="margin-right: 4px"
            />Traveler
          </div>
          <div v-if="tab !== 2" class="feature pointer" @click="tab = 2">Local Assist</div>
          <div v-else class="feature active">
            <img
              :src="require(`../assets/works/active.png`)"
              alt=""
              style="margin-right: 4px"
            />Local Assist
          </div>
          <div v-if="tab !== 3" class="feature pointer" @click="tab = 3">Send Package</div>
          <div v-else class="feature active">
            <img
              :src="require(`../assets/works/active.png`)"
              alt=""
              style="margin-right: 4px"
            />Send Package
          </div>
        </div>
      </section>

      <!--========== CREATE ==========-->
      <section v-if="tab === 1" class="share section__alt bd-container">
        <div class="create__container bd-grid">
          <div class="order__data">
            <div class="step">Step One</div>
            <p class="create-head">
              Create an <span class="primary-color"> account </span>
            </p>
            <div style="margin-bottom: 4px">
              Create an account and fill in your details
            </div>
            <ul>
              <li>
                Our processes encourage manual fill-in of details to help with
                our background check and keep the platform secure.
              </li>
              <li>
                You are to then go ahead to Verify Your Account using any of the
                available verification methods ( NIN or BVN ).
              </li>
              <li>
                Please be rest assured your details are secure on Travella. Also
                note that Travella is secure because we do not allow unvetted
                and Unverified persons to be able to pick up packages for
                others.
              </li>
            </ul>
          </div>
          <div class="order__img">
            <img :src="require(`../assets/works/create.png`)" alt="" />
          </div>
        </div>

        <div class="create__container bd-grid">
          <div class="share__data">
            <div class="step">Step Two</div>
            <p class="create-head">
              Log in to see & pick-up
              <span class="primary-color">packages</span>
            </p>
            <div style="margin-bottom: 4px">
              To pick up a package, click on 'Parcel' at the bottom Tab of the
              home screen.
            </div>
            <ul>
              <li>
                Select the package location (where you are picking the package
                from) and destination (where it's headed to) and follow the
                prompts.
              </li>
            </ul>
          </div>
          <div class="create__img">
            <img :src="require(`../assets/works/pick.png`)" alt="" />
          </div>
        </div>

        <div class="create__container bd-grid">
          <div class="order__data">
            <div class="step">Step Three</div>
            <p class="create-head">
              You can also
              <span class="primary-color"
                >Notify <br />Travella of your Trips</span
              >
            </p>
            <div style="margin-bottom: 4px">
              To notify Travella ahead of your next trip, that you are open to
              package delivery en route your next destination
            </div>
            <ul>
              <li>Click on Notify Travella of Trips</li>
              <li>
                Click on add schedule an fill in your location, destination and
                time details.
              </li>
              <li>
                This allows you to find as much packages as possible to cover
                for your transportation fees and save an extra. Get started by
                downloading the Travella App today.
              </li>
            </ul>
          </div>
          <div class="order__img">
            <img :src="require(`../assets/works/group.png`)" alt="" />
          </div>
        </div>
      </section>

      <section v-if="tab === 2" class="share section__alt bd-container">
        <div class="create__container bd-grid">
          <div class="order__data">
            <div class="step">Step One</div>
            <p class="create-head">
              Pick Up Packages
              <span class="primary-color">
                within<br />
                your vicinity
              </span>
            </p>
            <div style="margin-bottom: 8px; margin-top: 8px">
              There are lot persons in need of people to shop on their behalf.
              You can make extra money by picking up and delivering packages for
              other persons in your vicinity.
            </div>
            <div>
              Make sure you turn on the local assists toggle at the top of the
              home screen by clicking on the toggle button to keep yourself
              notified of packages you can pickup around you for extra cash.
            </div>
          </div>
          <div class="order__img">
            <img :src="require(`../assets/works/localassist.png`)" alt="" />
          </div>
        </div>
      </section>

      <section v-if="tab === 3" class="share section__alt bd-container">
        <div class="create__container bd-grid">
          <div class="order__data">
            <div class="step">Step One</div>
            <p class="create-head">
              Send a Package , and let <br />
              our Travelers <span class="primary-color"> do the magic! </span>
            </p>
            <div style="margin-bottom: 4px">
              To send a package, click on the "Send a Package" tab at the top of
              your home screen.
            </div>
            <ul>
              <li>
                Write a brief description of your package and select your
                package category in the pop-up box.
              </li>
              <li>
                Enter your preferred budget and an image or two (Optional) of
                your package.
              </li>
              <li>
                State your package destination (State and City). Also, select
                your pickup location (State and City) to help the sender know
                where to pick up your package
              </li>
              <li>
                Finally, fill in the details (Name and Phone number) of the
                individual receiving your package. This is how the sender keeps
                in touch with the receiver
              </li>
              <li>Your package is good to go!</li>
            </ul>
          </div>
          <div class="order__img">
            <img :src="require(`../assets/works/Sendpackage.png`)" alt="" />
          </div>
        </div>

        <div class="create__container bd-grid">
          <div class="share__data">
            <div class="step">Step Two</div>
            <p class="create-head">
              You can also
              <span class="primary-color">Check for Travelers</span>
            </p>
            <div style="margin-bottom: 4px">
              This is a faster option to allow you to check for active travelers
              that fit into your immediate schedule.
            </div>
            <ul>
              <li>
                Click on 'check for travellers' on the home screen and click on
                the location and destination of your package.
              </li>
              <li>
                Results for available travellers for your package delivery
                destination comes up.
              </li>
            </ul>
          </div>
          <div class="create__img">
            <img :src="require(`../assets/works/Checktraveler.png`)" alt="" />
          </div>
        </div>
      </section>

      <Semifooter />
    </main>
  </div>
</template>

<script>
import Semifooter from "../components/SemifooterAlt.vue";
export default {
  components: { Semifooter },
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  data() {
    return {
      tab: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
/*========== CLASS CSS ==========*/

/*========== LAYOUT ==========*/

/*========== HOME ==========*/
.primary-color {
  color: #ff6600;
}

.feature-grid {
  display: grid;
  grid-template-columns: 140px 160px 160px;
  gap: 45px;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    grid-template-columns: 30% 30% 30%;
    gap: 2%;
  }
}

.feature-grid .feature {
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  @media (max-width: 768px) {
    padding: 6px;
    font-size: 12px;
    border-radius: 6px;
  }
}

.feature-grid .feature.active {
  background: rgba(255, 102, 0, 0.1);
  color: #ff6600;
}

.home__container {
  row-gap: 0.5rem;
}

.home__img {
  width: 280px;
  justify-self: center;
}

.bd__background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border-radius: 50px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url("../assets/works/new-banner.png"), lightgray 50% / cover no-repeat;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

.travella {
  border-radius: 8px;
  background: url("../assets/works/btn-bg.png");
  @media screen and (max-width: 768px) {
    border-radius: 4px;
    padding: 6px 12px;
  }
}

.step {
  border-radius: 49px;
  background: rgba(255, 102, 0, 0.12);
  text-transform: uppercase;
  color: #ff6600;
  font-size: 16px;
  font-weight: 600;
  padding: 8px;
  width: fit-content;
  @media screen and (max-width: 768px) {
    border-radius: 4px;
    font-size: 12px;
    padding: 4px;
    margin: auto;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the color and opacity as needed */
}

.store__icon {
  width: 7.125rem;
  height: 2.5rem;
  margin: 0.5rem;
  border-radius: 0.6rem;
  opacity: 1;
  background-color: white !important;
  border-color: transparent !important;
  image-rendering: -webkit-optimize-contrast;
  @media screen and (min-width: 576px) {
    width: 8.125rem;
  }
}

.home__title {
  font-size: var(--h3-font-size);
  color: var(--header-color);
  font-weight: var(--font-light);
  margin-bottom: var(--mb-2);
}

.home__subdescription {
  font-size: var(--h2-font-size);
  color: var(--header-color);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-2);
  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
}

.home__data {
  max-width: 968px;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
}

.home__description {
  margin-bottom: var(--mb-3);
  color: var(--header-color);
  font-size: var(--biggest-font-size);
  font-weight: var(--font-bold);
  line-height: 55px;
}

/*========== SHARE ==========*/
.share__data {
  text-align: center;
}

.share__description {
  margin-bottom: var(--mb-2);
}

.share__img {
  /* width: 280px; */
  justify-self: center;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*========== WORK ==========*/
.download__underline {
  border-bottom: 3px solid #e4e4e4;
}

.download {
  margin-top: 6rem;
}

/*========== CREATE ==========*/
.create__img {
  justify-self: start;
}

.order__img {
  justify-self: end;
}

.create__img,
.share__img,
.order__img {
  margin: var(--mb-4);
  img {
    max-width: 280px;
  }
}

.order__img {
  img {
    float: right;
  }
}

.create__container {
  row-gap: 0.5rem;
  margin: 0;
}

.create-head {
  color: var(--title-color);
  font-weight: var(--font-bold);
  font-size: var(--medium-font-size);
  line-height: 40px;
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    font-size: 1.3rem;
  }
}

.order__data {
  text-align: center;
}

.section__footerr {
  padding: 4rem 0 0;
}

/* .footerr__apps {
  margin: 7px;
} */

/*========== MEDIA QUERIES ==========*/

/* For full-screen images on small screens */
@media screen and (max-width: 359px) {
  .home__img,
  .share__img,
  .create__img,
  .order__img,
  .send__img {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .ft__background {
    background-image: none;
  }

  .home__title,
  .home__description {
    color: var(--header-color);
  }

  .button__alt {
    color: var(--first-color);
    border: 1px solid var(--first-color);
  }

  .share__container {
    margin-top: 0rem;
    margin-bottom: -6rem;
  }
}

@media screen and (min-width: 576px) {
  .home__container,
  .share__container,
  .create__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .home__container {
    padding: 3rem 3rem 9rem;
  }

  .share__container {
    margin-top: -11rem;
    margin-bottom: -6rem;
  }

  .create__img {
    order: -1;
  }

  /* .order__data {
    order: 2;
  } */

  .section-title-center,
  .share__data,
  .order__data,
  .send__description {
    text-align: initial;
  }
}

@media screen and (min-width: 768px) {
  .download {
    margin-top: 8rem;
  }

  .section__alt {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .section__footerr {
    padding-top: 7rem;
  }

  .nav__list {
    display: flex;
    align-items: center;
  }

  .nav__item {
    margin-left: var(--mb-5);
    margin-bottom: 0;
  }

  .nav__toggle {
    display: none;
  }

  /* .footerr__logo {
    display: none;
  } */

  .change-theme {
    position: initial;
    margin-left: var(--mb-4);
  }

  .home__container {
    padding: 9rem 2rem;
  }

  // .home__container {
  //   padding: 6rem;
  // }

  .share__container {
    padding: 0 2rem;
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .create__container {
    padding: 0 2rem;
    margin: 0;
  }

  .accessory__container {
    grid-template-columns: repeat(3, 224px);
    justify-content: center;
  }

  .accessory__content {
    padding: 0.5rem 1.5rem 1.5rem;
  }

  .accessory__img {
    width: 120px;
    margin-bottom: var(--mb-1);
  }

  .accessory__title,
  .accessory__category {
    text-align: initial;
  }

  .accessory__button {
    padding: 0.75rem;
  }

  .send {
    background: none;
  }

  .send__container {
    background-color: var(--first-color-send);
    padding: 2rem;
    border-radius: 1.5rem;
  }
}
@media screen and (min-width: 968px) {
  .download {
    margin-top: 4rem;
  }

  .home__container,
  .share__container,
  .create__container,
  .send__container {
    column-gap: 5rem;
  }
}

@media (max-width: 768px) {
  .create__img,
  .share__img,
  .order__img {
    justify-self: center;
    img {
      max-width: 240px;
    }
  }

  .order__img {
    margin: 0;
  }

  .section-title-center.list {
    font-size: 0.9rem;
  }

  .create__container {
    margin: 1.5rem 0 2rem;
  }

  .home__container {
    border-radius: unset;
    box-shadow: unset;
    margin: unset;
    max-width: unset;
    width: unset;
  }

  .home__description {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    line-height: 30px;
  }

  .download {
    margin-top: 3.5rem;
  }
}
</style>
