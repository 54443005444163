<template>
  <div class="footer__div">
    <footer>
      <div class="container">
        <div class="left">
          <div class="col-1">
            <router-link class="header" :to="{ name: 'Home' }">
              <img
                :src="require(`../assets/logo_colored.png`)"
                alt="TRAVELLA_Logo"
                class="logo_img"
              />
            </router-link>
            <p class="footer">
              We're a peer-to-peer logistics platform which enables individual
              passengers to transport products for a faster and less expensive
              delivery.
            </p>
            <ul>
              <li>
                <a href="https://www.facebook.com/Travellaio" target="_blank"
                  ><facebook class="svg-icon"
                /></a>
              </li>
              <li>
                <a href="https://twitter.com/Travellaio?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank"
                  ><twitter class="svg-icon"
                /></a>
              </li>
              <li>
                <a href="https://www.instagram.com/mytravellaapp?igsh=MTVrcXBiaXJvemls&utm_source=qr" target="_blank"
                  ><instagram class="svg-icon"
                /></a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/travellaio/"
                  target="_blank"
                  ><linkedin class="svg-icon"
                /></a>
              </li>
            </ul>
          </div>
          <div class="col-2" style="margin-right: 0px">
            <ul class="quick-links" style="margin: 0 auto">
              <p class="link link-title">Quicklinks</p>
              <router-link class="link" :to="{ name: 'FAQ' }">FAQ</router-link>
              <router-link class="link" :to="{ name: 'Home' }"
                >Partnership</router-link
              >
              <router-link class="link" :to="{ name: 'Hiring' }"
                >Developer</router-link
              >
              <router-link class="link" :to="{ name: 'Contact' }"
                >Company</router-link
              >
              <router-link class="link" :to="{ name: 'Hiring' }"
                >Join our team</router-link
              >
              <router-link class="link" :to="{ name: 'Policy' }"
                >Terms and Conditions
              </router-link>
            </ul>
          </div>
          <div class="col-2" style="margin-right: 0px">
            <ul class="quick-links" style="margin-left: auto">
              <p class="link link-title">Contact Us</p>
 
              <p class="footer">+2349068699900</p>
 
              <p class="footer">info@travella.io</p>
 
              <p class="footer">
                House 5, Green Garage, Stateline RD<br />FUTA south gate, Akure,
                Ondo state, Nigeria.
              </p>
            </ul>
          </div>
          <!-- <div class="col-2">
            <ul class="quick-links">
              <router-link class="link" :to="{ name: 'Policy' }"
                >Terms and Conditions
              </router-link>
              <router-link class="link" :to="{ name: 'FAQ' }">FAQ</router-link>
            </ul>
          </div> -->
        </div>
      </div>
    </footer>
    <div class="subcontainer footer-policy">
      <div class="container footer__container">
        <div class="footer__content">All Right Reserved | Travella.io</div>
 
        <div class="footer__content2">
          <router-link class="footer__content2" :to="{ name: 'Policy' }">
            Privacy Policy</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import facebook from "../assets/icons/Facebook.svg";
import twitter from "../assets/icons/Twitter.svg";
import instagram from "../assets/icons/Instagram.svg";
import linkedin from "../assets/icons/LinkedIn.svg";
export default {
  name: "footer-vue",
  components: {
    facebook,
    twitter,
    instagram,
    linkedin,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    admin() {
      return this.$store.state.profileAdmin;
    },
  },
};
</script>
 
<style lang="scss" scoped>
.footer-policy {
  height: var(--sub-footer-height);
  background-color: var(--primary-color);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  padding: 0.7rem 25px;
  color: var(--footer-color);
}
 
.footer__data {
  max-width: 968px;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
 
.footer__container {
  // padding: 0.7rem 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--small-font-size);
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .footer__content2 {
    color: white !important;
  }
}
 
.footer__content {
  justify-content: center;
}
.subcontainer {
  width: 100%;
  margin: 0;
}
 
.footer__div {
  // position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
 
footer {
  padding: 80px 25px 40px;
  background-color: var(--footer-color);
  // font-weight: var(--font-semi-bold);
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    @media (min-width: 800px) {
      // flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 0px;
    }
 
    // > div {
    //   display: flex;
    //   flex: 1;
    // }
 
    .left {
      gap: 45px;
      color: var(--black-color);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: var(--footer-font-size);
      @media (min-width: 768px) {
        flex-direction: row;
        gap: 0;
      }
      // @media (max-width: 768px) {
      //   flex-direction: row;
      //   align-items: start;
      //   gap: 0;
      // }
 
      .header {
        text-align: center;
        font-size: 24px;
        color: var(--black-color);
        // margin-bottom: 16px;
        text-decoration: none;
        font-weight: 600;
        @media (min-width: 800px) {
          text-align: initial;
        }
        .logo_img {
          width: 100px;
          float: left;
          // @media (max-width: 768px) {
          //   width: 35%;
          // }
        }
      }
      ul {
        gap: 16px;
        list-style: none;
        display: flex;
      }
      ul.quick-links {
        gap: 0;
        list-style: none;
        display: flex;
      }
 
      .col-1,
      .col-2 {
        gap: 20px;
        // margin-right: var(--mb-3);
        display: flex;
        flex: 1;
        @media (min-width: 768px) {
          gap: 10px;
          margin-right: var(--mb-3);
        }
      }
 
      .col-1 {
        flex-direction: column;
 
        h2 {
          text-align: center;
          @media (min-width: 800px) {
            text-align: initial;
          }
        }
        ul {
          margin-top: auto;
          justify-content: start;
 
          li {
            display: flex;
            align-items: center;
            .svg-icon {
              // width: 24px;
              height: auto;
              color: var(--black-color);
            }
          }
        }
      }
 
      .col-2 {
        ul {
          height: 100%;
          justify-content: center;
          flex-direction: column;
          flex-wrap: wrap;
          @media (min-width: 800px) {
            flex-direction: column;
          }
          .link-title {
            cursor: text;
            margin-bottom: var(--mb-3);
            font-weight: var(--font-bold);
            @media (max-width: 768px) {
              margin-bottom: var(--mb-2);
            }
          }
        }
      }
    }
 
    .right {
      gap: 32px;
      color: var(--black-color);
      align-items: center;
      flex-direction: column;
      @media (min-width: 800px) {
        align-items: center;
        justify-content: flex-end;
        gap: 0;
      }
    }
 
    p.footer {
      // margin-top: auto;
      color: var(--black-color);
    }
  }
}
</style>
 

