<template>
  <div class="blog-card">
    <div class="info">
      <img
        :src="require(`../assets/landing-page/${post.image}.png`)"
        alt=""
        class="img"
      />
      <p>{{ post.subtitle }}</p>
      <h3 class="title">{{ post.title }}</h3>
      <h4 class="content">{{ post.content }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "blogCard",
  props: ["post"],
  components: {},
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.slider-section-visible {
  .blog-card {
    // max-width: fit-content !important;
    border-radius: 10px;
  }
}

.blog-card {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  //  box-shadow: 3px 3px 7px 7px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  max-width: 300px;
  min-height: 250px;
  margin: 20px auto;
  transition: 0.5s ease all;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  @media (min-width: 576px) {
    min-height: 250px;
    border-radius: 25px;
    margin: 20px auto;
  }
  @media (min-width: 768px) {
    min-height: 270px;
    margin: 20px auto;
  }
  @media (min-width: 968px) {
    min-height: 320px;
    margin: 30px auto;
  }

  &:hover {
    transform: rotateZ(-1deg) scale(1.01);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .info {
    display: flex;
    // justify-content: center;
    align-content: center;
    flex-direction: column;
    height: 100%;
    z-index: 3;
    padding: 12px 16px 32px;
    color: #000;

    .title {
      color: var(--mild-black-color);
    }

    .content {
      // color: var(--black-color);
    }

    .img {
      margin: 10px auto;
    }

    h3,
    h4,
    p {
      display: flex;
      justify-content: center;
    }

    h3 {
      padding-bottom: 8px;
      font-size: var(--h3-font-size);
    }
    h4 {
      padding-bottom: 8px;
      font-size: var(--normal-font-size);
      font-weight: var(--font-light);
      text-align: center;
    }
    p {
    }
  }
}
</style>
