<template>
  <div>
    <section class="home bd-container download__section" id="home">
      <div class="create__container bd-grid">
        <div class="order__data">
          <p class="create-head">
            Connect with us on
            <span style="color: var(--primary-color)">social media</span>
          </p>
          <h3 class="section-title-center col-1">
            <ul>
              <li>
                <a href="https://www.facebook.com/Travellaio" target="_blank">
                  <img
                    :src="require(`../assets/home/Facebook.png`)"
                    alt="facebook"
                    class="svg-icon"
                  />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/Travellaio?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor">
                  <img
                    :src="require(`../assets/home/Twitter.png`)"
                    alt="twitter"
                    class="svg-icon"
                /></a>
              </li>
              <li>
                <a href="https://www.instagram.com/mytravellaapp?igsh=MTVrcXBiaXJvemls&utm_source=qr" target="_blank">
                  <img
                    :src="require(`../assets/home/Instagram.png`)"
                    alt="instagram"
                    class="svg-icon"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/travellaio/">
                  <img
                    :src="require(`../assets/home/LinkedIn.png`)"
                    alt="linkedIn"
                    class="svg-icon"
                /></a>
              </li>
            </ul>
          </h3>
        </div>
        <div class="order__img">
          <img :src="require(`../assets/home/Contact.png`)" alt="" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Socials",
};
</script>

<style lang="scss" scoped>
.download__section {
  margin: 85px auto;
  @media (min-width: 576px) {
    margin: 85px auto;
  }
  @media (min-width: 768px) {
    margin: 100px auto;
  }
  @media (min-width: 968px) {
    margin: 120px auto;
  }
}
.col-1 {
  flex-direction: column;
  gap: 10px;
  margin: 20px auto;
  display: flex;
  flex: 1;
  @media (min-width: 768px) {
    gap: 0;
    margin-right: var(--mb-5);
  }

  ul {
    margin-top: auto;
    justify-content: center;
    display: flex;
    gap: 20px;
    list-style: none;
    @media (min-width: 768px) {
      justify-content: initial;
      gap: 35px;
    }

    li {
      display: flex;
      align-items: center;
      .svg-icon {
        width: 35px;
        @media (min-width: 768px) {
          width: 40px;
        }
      }
    }
  }
}
.create__container {
  row-gap: 0.5rem;
  margin: 2rem 0;
  @media screen and (min-width: 576px) {
    align-items: center;
  }
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 2rem;
    margin: 2rem 0;
  }
  @media screen and (min-width: 968px) {
    column-gap: 5rem;
  }

  .order__data {
    text-align: center;
    @media screen and (min-width: 576px) {
      text-align: initial;
    }
    .section-title-center {
      @media screen and (min-width: 576px) {
        text-align: initial;
      }
    }
  }
  .create-head {
    color: var(--title-color);
    font-weight: var(--font-bold);
    font-size: var(--semi-large-font-size);
    text-align: center;
    @media screen and (min-width: 768px) {
      text-align: initial;
    }
  }

  .order__img {
    justify-self: center;
    margin: var(--mb-4);
    // max-width: 350px;
    @media screen and (min-width: 968px) {
      width: 100%;
    }
    @media (min-width: 900px) {
      max-height: 800px;
    }
    @media screen and (min-width: 576px) {
      width: 100%;
    }

    @media screen and (max-width: 359px) {
      width: 100%;
    }
    img {
      max-height: 600px;
      float: right;
    }
  }
}
</style>
