import Vue from "vue";
import Vuex from "vuex";
import todos from "../logic";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    blogPosts: [],
    FAQs: [],
    singleBlogPost: [],
  },
  mutations: {
    setBlogPost(state, payload) {
      state.blogPosts = payload;
    },
    setFAQs(state, payload) {
      state.FAQs = payload;
    },
    setSinglePost(state, payload) {
      state.singleBlogPost = payload;
    },
  },
  actions: {
    async getPost({ commit }) {
      const response = await todos.get("blog/all");
      commit("setBlogPost", response.data.data);
    },
    async getFAQs({ commit }) {
      const response = await todos.get("faq/all");
      commit("setFAQs", response.data.data);
    },
    async getSinglePost({ commit }, { id }) {
      const response = await todos.get("blog/all");
      console.log('hello', id, response.data.data);
      const singlePost = response.data.data.filter((post) => {
        return post._id === id;
      });
      console.log(singlePost);
      commit("setSinglePost", singlePost);
    },
  },
  modules: {},
});
