<template>
  <div class="bd-container" v-if="currentBlog.length > 0">
    <div class="single__image">
      <img :src="currentBlog[0].image" alt="author" class="single__icon" />
    </div>
    <div class="single__container">
      <div class="blog__author">
        <div class="author__img">
          <!-- <img
            :src="require(`../assets/blog/author.png`)"
            alt="author"
            class="author__icon"
          /> -->
          {{ currentBlog[0].author }} - Posted on
          {{ convertTime1(currentBlog[0].createdAt) }}
        </div>
      </div>
      <div class="blog__head">
        {{ currentBlog[0].title }}
      </div>
      <!-- <div class="contact__subhead">
        {{currentBlog[0].title}}
      </div> -->
      <!-- <div class="contact__subhead">
        {{ currentBlog[0].body }}
      </div> -->
      <div class="contact__subhead ql-snow">
        <div class="ql-editor" v-html="currentBlog[0].body"></div>
      </div>
    </div>
  </div>
  <div v-else>
    <fold style="margin-top: 6rem"></fold>
  </div>
</template>

<script>
import moment from "moment";
// import todos from "../logic";

export default {
  name: "ViewBlog",
  data() {
    return {
      // isLoading: true,
      // currentBlog: [],
    };
  },
  created() {
    this.getSinglePosts();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  // async mounted() {
  //   this.getSinglePosts();
  // },
  computed: {
    currentBlog() {
      return this.$store.state.singleBlogPost;
    },
  },
  methods: {
    async getSinglePosts() {
      this.$store.dispatch("getSinglePost", {
        id: this.$route.params.blogid,
      });
      this.isLoading = false;

      // this.currentBlog = await this.$store.state.blogPosts.filter((post) => {
      //   return post._id === this.$route.params.blogid;
      // });
      // this.$store.commit("setSingleBlogPost", this.currentBlog);
    },
    convertTime1(time) {
      if (!time) {
        return;
      }
      return moment(time).format("LL");
    },
  },
};
</script>

<style lang="scss" scoped>
.single__image {
  margin: 2rem 0;
  height: 624px;
  @media screen and (max-width: 576px) {
    height: 230px;
  }
  @media screen and (min-width: 576px) and (max-width: 768px) {
    height: 430px;
  }
}
.single__icon {
  width: 100%;
  height: 100%;
}
.author__img {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 1rem 0;
  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
}
.blog__head {
  font-weight: var(--font-semi-bold);
  line-height: 54px;
  font-size: var(--large-font-size);
  color: #1d3444;
  // max-width: 400px;
}
.author__icon {
  margin: 0 10px 0 0;
}
.contact__subhead {
  margin: 1.5rem 0;
}
.single__container {
  max-width: 750px;
  margin: 5px auto;
}
</style>
