<template>
  <div>
    <VueSlickCarousel v-bind="settings">
      <div v-for="(testimonial, index) in testimonials" :key="index">
        <div class="card">
          <div class="card-body">
            <div class="card-content">
              <div class="card-title">
                <img
                  :src="require(`../assets/landing-page/testimony.png`)"
                  alt=""
                />
              </div>
              <div class="card-text">
                <p>
                  {{ testimonial.message }}
                </p>
              </div>
              <div class="card-user">
                <div class="card-avatar">
                  <img
                    :src="require(`../assets/landing-page/user-pic.png`)"
                    alt=""
                  />
                </div>
                <div class="user-details">
                  <div class="user-name">{{ testimonial.name }}</div>
                  <div class="user-fulldetails">{{ testimonial.position }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Testimonial",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        dots: true,
        arrows: true,
        dotsClass: "slick-dots custom-dot-class",
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 2,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
              dots: true,
            },
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
            },
          },
        ],
      },
      testimonials: [
        {
          message:
            "Travella provided excellent service. They enable my team and me to do more.",
          name: "Igbala John",
          position: "CEO, Nnefa clothings",
        },
        {
          message:
            "I appreciate the service and the fact that the team is always willing to go above and beyond to make the experience as pleasant as possible. And what's even more intriguing is that packages are delivered by individual passengers.",
          name: "Alabi Tabitha",
          position: "CEO, Tabitha fashion house",
        },
        {
          message:
            "It's still hard to believe, but it's a valuable service. I am now able to make and sell at a more convenient pricing than previously.",
          name: "Emmanuel Olowolohun",
          position: "Merchant",
        },
        {
          message:
            "The application and process are simple, and I had no trouble finding travelers to pick up my things and move them for the lowest and most affordable fees.",
          name: "Adepoju Ronke",
          position: "Online Seller",
        },
        {
          message:
            "Travella provided excellent service. They enable my team and me to do more.",
          name: "Igbala John",
          position: "CEO, Nnefa clothings",
        },
        {
          message:
            "I appreciate the service and the fact that the team is always willing to go above and beyond to make the experience as pleasant as possible. And what's even more intriguing is that packages are delivered by individual passengers.",
          name: "Alabi Tabitha",
          position: "CEO, Tabitha fashion house",
        },
        {
          message:
            "It's still hard to believe, but it's a valuable service. I am now able to make and sell at a more convenient pricing than previously.",
          name: "Emmanuel Olowolohun",
          position: "Merchant",
        },
        {
          message:
            "The application and process are simple, and I had no trouble finding travelers to pick up my things and move them for the lowest and most affordable fees.",
          name: "Adepoju Ronke",
          position: "Online Seller",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  padding-top: 20px;
  text-align: center;
}
.carousel {
  width: 90%;
  margin: 0px auto;
}
.slick-slide {
  margin: 10px;
}
.slick-slide img {
  width: 100%;
}

.card {
  border: 0.821853px solid #efefef;
  background: #fff;
  box-shadow: 0px 9.86224px 28.7649px rgba(8, 96, 208, 0.12);
  border-radius: 4.10926px;
  margin: 10px;
  height: 345px;
  &:hover {
    opacity: 1;
    transition: 1s ease all;
    -webkit-transform: translate(20px, -10px);
    -ms-transform: translate(10px, -10px);
    transform: translate(10px, -10px);
  }
}
.card-body {
  width: 100%;
  vertical-align: top;
}
.card-content {
  text-align: left;
  color: #5c5c5c;
  padding: 30px;
}
.card-title {
  width: 28px;
  height: 22px;
  display: flex;
  //   align-self: center;
  //   align-items: center;
  //   justify-content: center;
}
.card-text {
  font-size: 14px;
  font-weight: 300;
  padding: 20px 0;
}

.card-user {
  display: grid;
  gap: 10px;
  grid-template-columns: 50px 1fr;
  position: absolute;
  bottom: 0;
  margin-bottom: 30px;
  .user-name {
    font-weight: var(--font-semi-bold);
  }
  .user-fulldetails {
    font-size: var(--small-font-size);
    font-weight: var(--font-light);
  }
}
</style>
