<template>
  <div>
    <div class="blog__bg">
      <div class="bd-container blog__box">
        <div class="blog__container">
          <div class="blog__left">
            <div class="blog__author">
              <div class="author__img">
                <img
                  :src="require(`../assets/blog/author.png`)"
                  alt="author"
                  class="author__icon"
                />
                Adebayo Moses - Posted on 11th January 2022
              </div>
            </div>
            <div class="blog__head">
              Travella's disruptive impact on the global supply chain in the
              twenty-first century.
            </div>
            <div class="contact__subhead">
              Economic disruption occurs as a result of the development of a
              more comprehensive approach to processes and a modus operandi for
              how work is done and performed. The Travella team is committed to
              ensuring affordable and rapid distribution in the twenty-first
              century in order to foster and promote the growth of innovation in
              the fourth industry.
            </div>
            <!-- <router-link class="link blog-btn" :to="{ name: 'Home' }">
              Read More</router-link
            > -->
          </div>
          <div class="blog__right">
            <img
              :src="require(`../assets/blog/blog-image.png`)"
              alt="blog"
              class="blog__icon"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="post__container bd-container">
      <div class="post__title">All Posts</div>
      <div class="posts__section" v-if="posts.length > 0">
        <div class="post__card" v-for="post in posts" :key="post._id">
          <div class="card__image">
            <img :src="post.image" alt="post" class="post_img" />
          </div>
          <div class="card__content">
            <div class="card__title text-title-truncate">
              {{ post.title }}
            </div>
            <!-- <div class="ql-snow">
              <div class="ql-editor" v-html="post.body"></div>
            </div>
            <div class="card__subtitle text-body-truncate">
              {{ post.body }}
            </div> -->
            <router-link
              class="link blog-btn"
              :to="{ name: 'ViewBlog', params: { blogid: post._id } }"
            >
              Learn More <ArrowRight
            /></router-link>
          </div>
        </div>
      </div>
      <div v-else>
        <fold style="margin-top: 5rem"></fold>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowRight from "../assets/blog/arrow-right.svg";
// import todos from "../logic";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    ArrowRight,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  computed: {
    posts() {
      return this.$store.state.blogPosts;
    },
  },
};
</script>

<style lang="scss" scoped>
.blog__bg {
  background: rgba(255, 102, 0, 0.3);
  //   min-height: calc(100vh - 110px);
  .blog__box {
    padding: 1rem 0;
    @media screen and (min-width: 968px) {
      padding: 4rem 0;
    }

    .blog__container {
      display: grid;
      gap: 4rem;
      padding: 2rem 0;
      align-items: start;
      grid-template-columns: repeat(1, 1fr);
      @media screen and (min-width: 968px) {
        grid-template-columns: repeat(2, 1fr);
        // padding: 8rem 0;
      }
      .blog__left,
      .blog__right {
        max-width: 400px;
        @media screen and (min-width: 968px) {
          max-width: 495px;
        }
      }
      .blog-btn {
        color: var(--primary-color);
      }
      .blog__head {
        font-weight: var(--font-semi-bold);
        line-height: 54px;
        font-size: var(--large-font-size);
        color: #1d3444;
      }
      .contact__subhead {
        margin: 1.5rem 0;
      }
      .blog__icon {
        justify-self: center;
        width: 100%;
        margin: auto;
        // @media (max-width: 576px) {
        //   margin: 2rem auto;
        // }
      }
    }
  }
}
.text-title-truncate {
  // white-space: nowrap;
  max-width: 290px;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-body-truncate {
  // white-space: nowrap;
  max-width: 290px;
  height: 78px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.author__img {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 1rem 0;
  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
}
.author__icon {
  margin: 0 10px 0 0;
}
.post__title {
  font-weight: var(--font-semi-bold);
  line-height: 56px;
  font-size: var(--medium-font-size);
  color: #1d3444;
}
.post__container {
  padding: 1rem 0;
  @media screen and (min-width: 968px) {
    padding: 2rem 0;
  }
  .post__title {
    margin: 1rem 0;
  }
}
.posts__section {
  display: grid;
  row-gap: 4rem;
  column-gap: 2rem;
  margin: 1.5rem 0;
  padding: 0.5rem 0;
  align-items: start;
  grid-template-columns: repeat(1, 1fr);
  @media screen and (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 968px) {
    grid-template-columns: repeat(3, 1fr);
  }
  .post__card {
    background: #edf7ff;
    border-radius: 18.2183px;
    max-width: 400px;
    .card__image {
      width: 100%;
      //   height: 50%;
      //   .post_img {
      //     height: 50%;
      //   }
    }
    .card__content {
      padding: 1.3rem;
      .card__title {
        font-weight: var(--font-semi-bold);
        font-size: 21px;
        line-height: 33px;
      }
      .card__subtitle {
        font-weight: var(--font-medium);
        font-size: 14px;
        line-height: 26px;
        color: #5b5b5b;
        margin: 1rem 0;
      }
      .blog-btn {
        color: var(--primary-color);
      }
    }
  }
}
</style>
