import Vue from "vue";
import VueRouter from "vue-router";
import NotFoundComponent from "../views/NotFoundComponent.vue"
import Home from "../views/Home.vue";
import Register from "../views/Register.vue";
import Work from "../views/Work.vue";
import FAQ from "../views/FAQ.vue";
import Contact from "../views/Contact.vue";
import Blog from "../views/Blog.vue";
import Hiring from "../views/Hiring.vue";
import Application from "../views/Application.vue";
import Policy from "../views/Policy.vue";
import SingleBlog from "../views/SingleBlog.vue";

Vue.use(VueRouter);

const routes = [

  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/how-it-works",
    name: "Work",
    component: Work,
  },
  {
    path: "/frequently-asked-questions",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/contact-us",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/view-blog/:blogid",
    name: "ViewBlog",
    component: SingleBlog,
  },
  {
    path: "/we-are-hiring",
    name: "Hiring",
    component: Hiring,
  },
  {
    path: "/application",
    name: "Application",
    component: Application,
  },
  {
    path: "/privacy-policy",
    name: "Policy",
    component: Policy,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFoundComponent",
    component: NotFoundComponent
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
});

export default router;
