<template>
  <section class="" id="footerr">
    <div
      class="footerr__container sub-bd-container sm-grid ft__background ft__bg-image"
    >
      <div class="footerr__image-container">
        <img
          :src="require(`../assets/landing-page/mock-app.png`)"
          alt="Apps"
          class=""
        />
      </div>
      <div class="footerr__data">
        <p class="footerr__description">Download the <br />Travella App now</p>
        <div class="social_icons">
          <a
            href="https://play.google.com/store/apps/details?id=org.ibranch.travella"
            target="_blank"
            ><img
              :src="require(`../assets/works/playstore.png`)"
              alt="PlayStore"
              class="sfstore__icon"
          /></a>
          <a href="https://apps.apple.com/ng/app/travella/id1567772308"
            ><img
              :src="require(`../assets/works/appstore.png`)"
              alt="App_Store"
              class="sfstore__icon"
          /></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "semifooter",
};
</script>

<style lang="scss" scoped>
.footerr__content {
  justify-content: center;
}

.footerr__img {
  width: 280px;
  justify-self: center;
}

.footerr__link:hover {
  color: var(--first-color);
}

.footerr__social {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-right: var(--mb-3);
}

.footerr__social:hover {
  color: var(--first-color);
}

.footerr__image-container {
  margin-top: -18rem;
  margin-bottom: -12rem;
  @media screen and (max-width: 768px) {
    margin-bottom: -16rem;
  }
}

.footerr__container {
  padding: 3rem;
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0.5rem;
  }
}

.sm-grid {
  display: grid;
  grid-template-columns: 65% 35%;
  align-items: center;
}

.footerr {
  height: calc(var(--footer-height) + 1.5rem);
}

.social_icons {
  display: flex;
  flex-direction: column;
}

.footerr__copy {
  text-align: center;
  font-size: var(--small-font-size);
  color: var(--text-color-light);
  margin: 4px auto;
}

@media screen and (max-width: 768px) {
  .footerr {
    height: calc(var(--footer-height) + 5rem);
    display: inline-block;
  }

  .footerr__content {
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 576px) {
  .footerr__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
}
</style>
